import React, { useState, useRef, useMemo, useEffect } from "react";
import { Row, Select, Spin } from "antd";
import debounce from "lodash/debounce";

export default function DebounceSelect({
  fetchOptions,
  debounceTimeout = 800,
  ...props
}) {
  useEffect(() => {
    debounceFetcher("");
  }, []);
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Row>
      <Select
        style={{
          width: "80%",
        }}
        filterOption={false}
        showSearch={true}
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        {...props}
        options={options}
      />
      {props.children}
    </Row>
  );
}
