import React, { useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { api } from "../../../services/Api";
import { Tabs, Row, Col, Image, DatePicker } from "antd";
import { Link, useParams, useNavigate } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useState } from "react";
import moment from "moment/moment";

const { TabPane } = Tabs;

function Types() {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const [billHashMap, setBillHashmap] = useState({});

  const getBill = () => {
    if (companyId.length > 10) {
      return api.get("/bills", { params: { company: companyId } });
    }
  };
  const getCompany = () => {
    return api.get("/companies/" + companyId);
  };
  const {
    data: company_data,
    isLoading: company_loading,
    refetch: company_refetch,
  } = useQuery("company", getCompany);
  const {
    isloading,
    data: data1,
    refetch: data1_refetch,
  } = useQuery("billtype", getBill);
  
  console.log("bill data", data1);

  useEffect(() => {
    if (data1?.data) {
      let finalMap = {};
      data1?.data.docs.forEach((e) => {
        let temp = [];
        const key = moment(e.dateOfBillIssued).format("MMMM YYYY");
        if (key in finalMap) {
          console.log("key", key);
          temp = finalMap[key];
        }
        temp.push(e);
        finalMap = {
          ...finalMap,
          [key]: temp,
        };
      });
      console.log(finalMap);
      setBillHashmap(finalMap);
    }
  }, [data1]);

  const updateBill = (data) => {
    const payload = {
      dateOfBillIssued: data.createdAt,
    };
    console.log(data);
    return api.patch(`/bills/${data.id}`, payload);
  };

  const { mutate: update_bill_mutate, isLoading: update_bill_loading } =
    useMutation(updateBill, {
      onSuccess: () => {
        data1_refetch();
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });

  if (isloading) {
    return <h1>Loading ...</h1>;
  }

  return (
    <>
      <div className="main-container">
        <div>
          <span
            className="main-title link-element"
            onClick={() => {
              navigate("/dashboard/companies");
            }}
          >
            Companies/
          </span>
          <span
            className="main-subtitle link-element"
            onClick={() => {
              navigate(-1);
            }}
          >
            {company_data?.data.name}/
          </span>
          <span className="main-subtitle">Bill Items</span>
        </div>
        <div>
          <Tabs defaultActiveKey="1" style={{ justifyContent: "spaceBetween" }}>
            <TabPane tab="Sales Bill" key="1">
              <Tabs defaultActiveKey="11">
                <TabPane tab="Unposted Bills" key="11">
                  {Object.keys(billHashMap).map((key) => {
                    const filteredBills = billHashMap[key].filter(
                      (e) => e.billType === "Sales" && !e.isPost
                    );
                    if (filteredBills.length < 1) {
                      return <></>;
                    }
                    return (
                      <>
                        <h4>{key}</h4>
                        <Row gutter={24} style={{ marginTop: "1em" }}>
                          {filteredBills.map((data) => {
                            return (
                              <Col span={4} gap={2}>
                                <Image
                                  className="bill-image"
                                  src={data.attachment.url}
                                  alt="bill"
                                />
                                <Link to={`${data._id}`}>
                                  <h3 className="bill-post">
                                    Post this bill <AiOutlineArrowRight />
                                  </h3>
                                </Link>
                              </Col>
                            );
                          })}
                        </Row>
                      </>
                    );
                  })}
                </TabPane>

                <TabPane tab="Posted Bills" key="12">
                  {Object.keys(billHashMap).map((key) => {
                    const filteredBills = billHashMap[key].filter(
                      (e) => e.billType === "Sales" && e.isPost
                    );
                    if (filteredBills.length < 1) {
                      return <></>;
                    }
                    return (
                      <>
                        <h4>{key}</h4>
                        <Row gutter={24} style={{ marginTop: "1em" }}>
                          {filteredBills.map((data) => {
                            return (
                              <Col span={4} gap={2}>
                                <div className="overlay-image">
                                  <Image
                                    className="bill-image"
                                    src={data.attachment.url}
                                    alt="bill"
                                  />
                                  <DatePicker
                                    picker="month"
                                    placeholder=""
                                    className="overlay"
                                    onChange={(value) =>
                                      update_bill_mutate({
                                        id: data._id,
                                        createdAt: value,
                                      })
                                    }
                                  />
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      </>
                    );
                  })}
                </TabPane>
              </Tabs>
            </TabPane>
            <TabPane tab="Purchase Bill" key="2">
              <Tabs defaultActiveKey="13">
                <TabPane tab="Unposted Bills" key="13">
                  {Object.keys(billHashMap).map((key) => {
                    const filteredBills = billHashMap[key].filter(
                      (e) => e.billType === "Purchase" && !e.isPost
                    );
                    if (filteredBills.length < 1) {
                      return <></>;
                    }
                    return (
                      <>
                        <h4>{key}</h4>
                        <Row gutter={24} style={{ marginTop: "1em" }}>
                          {filteredBills.map((data) => {
                            return (
                              <Col span={4} gap={2}>
                                <Image
                                  className="bill-image"
                                  src={data.attachment.url}
                                  alt="bill"
                                />
                                <Link to={`${data._id}`}>
                                  <h3 className="bill-post">
                                    Post this bill <AiOutlineArrowRight />
                                  </h3>
                                </Link>
                              </Col>
                            );
                          })}
                        </Row>
                      </>
                    );
                  })}
                </TabPane>

                <TabPane tab="Posted Bills" key="14">
                  {Object.keys(billHashMap).map((key) => {
                    const filteredBills = billHashMap[key].filter(
                      (e) => e.billType === "Purchase" && e.isPost
                    );
                    if (filteredBills.length < 1) {
                      return <></>;
                    }
                    return (
                      <>
                        <h4>{key}</h4>
                        <Row gutter={24} style={{ marginTop: "1em" }}>
                          {filteredBills.map((data) => {
                            return (
                              <Col span={4} gap={2}>
                                <div className="overlay-image">
                                  <Image
                                    className="bill-image"
                                    src={data.attachment.url}
                                    alt="bill"
                                  />
                                  <DatePicker
                                    picker="month"
                                    placeholder=""
                                    className="overlay"
                                    onChange={(value) =>
                                      update_bill_mutate({
                                        id: data._id,
                                        createdAt: value,
                                      })
                                    }
                                  />
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      </>
                    );
                  })}
                </TabPane>
              </Tabs>
            </TabPane>
            <TabPane tab="Sales Return Bill" key="3">
              <Tabs defaultActiveKey="15">
                <TabPane tab="unposted Bills" key="15">
                  {Object.keys(billHashMap).map((key) => {
                    const filteredBills = billHashMap[key].filter(
                      (e) => e.billType === "Sales Return" && !e.isPost
                    );
                    if (filteredBills.length < 1) {
                      return <></>;
                    }
                    return (
                      <>
                        <h4>{key}</h4>
                        <Row gutter={24} style={{ marginTop: "1em" }}>
                          {filteredBills.map((data) => {
                            return (
                              <Col span={4} gap={2}>
                                <Image
                                  className="bill-image"
                                  src={data.attachment.url}
                                  alt="bill"
                                />
                                <Link to={`${data._id}`}>
                                  <h3 className="bill-post">
                                    Post this bill <AiOutlineArrowRight />
                                  </h3>
                                </Link>
                              </Col>
                            );
                          })}
                        </Row>
                      </>
                    );
                  })}
                </TabPane>

                <TabPane tab="Posted Bills" key="16">
                  {Object.keys(billHashMap).map((key) => {
                    const filteredBills = billHashMap[key].filter(
                      (e) => e.billType === "Sales Return" && e.isPost
                    );
                    if (filteredBills.length < 1) {
                      return <></>;
                    }
                    return (
                      <>
                        <h4>{key}</h4>
                        <Row gutter={24} style={{ marginTop: "1em" }}>
                          {filteredBills.map((data) => {
                            return (
                              <Col span={4} gap={2}>
                                <div className="overlay-image">
                                  <Image
                                    className="bill-image"
                                    src={data.attachment.url}
                                    alt="bill"
                                  />
                                  <DatePicker
                                    picker="month"
                                    placeholder=""
                                    className="overlay"
                                    onChange={(value) =>
                                      update_bill_mutate({
                                        id: data._id,
                                        createdAt: value,
                                      })
                                    }
                                  />
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      </>
                    );
                  })}
                </TabPane>
              </Tabs>
            </TabPane>
            <TabPane tab="Purchase Return Bill" key="4">
              <Tabs defaultActiveKey="17">
                <TabPane tab="Unposted Bills" key="17">
                  {Object.keys(billHashMap).map((key) => {
                    const filteredBills = billHashMap[key].filter(
                      (e) => e.billType === "Purchase Return" && !e.isPost
                    );
                    if (filteredBills.length < 1) {
                      return <></>;
                    }
                    return (
                      <>
                        <h4>{key}</h4>
                        <Row gutter={24} style={{ marginTop: "1em" }}>
                          {filteredBills.map((data) => {
                            return (
                              <Col span={4} gap={2}>
                                <Image
                                  className="bill-image"
                                  src={data.attachment.url}
                                  alt="bill"
                                />
                                <Link to={`${data._id}`}>
                                  <h3 className="bill-post">
                                    Post this bill <AiOutlineArrowRight />
                                  </h3>
                                </Link>
                              </Col>
                            );
                          })}
                        </Row>
                      </>
                    );
                  })}
                </TabPane>
                <TabPane tab="Posted Bills" key="18">
                  {Object.keys(billHashMap).map((key) => {
                    const filteredBills = billHashMap[key].filter(
                      (e) => e.billType === "Purchase Return" && e.isPost
                    );
                    if (filteredBills.length < 1) {
                      return <></>;
                    }
                    return (
                      <>
                        <h4>{key}</h4>
                        <Row gutter={24} style={{ marginTop: "1em" }}>
                          {filteredBills.map((data) => {
                            return (
                              <Col span={4} gap={2}>
                                <div className="overlay-image">
                                  <Image
                                    className="bill-image"
                                    src={data.attachment.url}
                                    alt="bill"
                                  />
                                  <DatePicker
                                    picker="month"
                                    placeholder=""
                                    className="overlay"
                                    onChange={(value) =>
                                      update_bill_mutate({
                                        id: data._id,
                                        createdAt: value,
                                      })
                                    }
                                  />
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      </>
                    );
                  })}
                </TabPane>
              </Tabs>
            </TabPane>

            <TabPane tab="Others" key="5">
              <Tabs defaultActiveKey="19">
                <TabPane tab="Unposted Bills" key="19">
                  {Object.keys(billHashMap).map((key) => {
                    const filteredBills = billHashMap[key].filter(
                      (e) => e.billType === "Others" && !e.isPost
                    );
                    if (filteredBills.length < 1) {
                      return <></>;
                    }
                    return (
                      <>
                        <h4>{key}</h4>
                        <Row gutter={24} style={{ marginTop: "1em" }}>
                          {filteredBills.map((data) => {
                            return (
                              <Col span={4} gap={2}>
                                <Image
                                  className="bill-image"
                                  src={data.attachment.url}
                                  alt="bill"
                                />
                                <Link to={`${data._id}`}>
                                  <h3 className="bill-post">
                                    Post this bill <AiOutlineArrowRight />
                                  </h3>
                                </Link>
                              </Col>
                            );
                          })}
                        </Row>
                      </>
                    );
                  })}
                </TabPane>

                <TabPane tab="Posted Bills" key="20">
                  {Object.keys(billHashMap).map((key) => {
                    const filteredBills = billHashMap[key].filter(
                      (e) => e.billType === "Others" && e.isPost
                    );
                    if (filteredBills.length < 1) {
                      return <></>;
                    }
                    return (
                      <>
                        <h4>{key}</h4>
                        <Row gutter={24} style={{ marginTop: "1em" }}>
                          {filteredBills.map((data) => {
                            return (
                              <Col span={4} gap={2}>
                                <div className="overlay-image">
                                  <Image
                                    className="bill-image"
                                    src={data.attachment.url}
                                    alt="bill"
                                  />
                                  <DatePicker
                                    picker="month"
                                    placeholder=""
                                    className="overlay"
                                    onChange={(value) =>
                                      update_bill_mutate({
                                        id: data._id,
                                        createdAt: value,
                                      })
                                    }
                                  />
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      </>
                    );
                  })}
                </TabPane>
              </Tabs>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default Types;
