import { Button, Col, Row, Table, Card, Form, Collapse, Select } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import moment from "moment";
import React, { useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { api } from "../../../services/Api";
import GenerateReportModal from "./GenerateReportModal";
import { Excel } from "antd-table-saveas-excel";
import CountingCard from "../../../components/common/CountingCard";
import Count from "../../../assets/images/inventory.png";

const { Panel } = Collapse;
const InventoryPage = () => {
  const { companyId } = useParams();
  const [filterForm] = Form.useForm();
  const [filters, setFilters] = useState({});
  const [productId, setProductId] = useState();
  const navigate = useNavigate();

  const getInventory = () => {
    return api.get("/inventories/items", {
      params: {
        company: companyId,
        ...filters,
      },
    });
  };
  const getInventoryOverview = () => {
    return api.get("/inventories/overviews", {
      params: {
        company: companyId,
        // ...filters,
      },
    });
  };
  const [isGenerateReportVisible, setGenerateReportVisible] = useState(false);
  const {
    data: inventory_data,
    isLoading: inventory_loading,
    refetch: inventory_refetch,
  } = useQuery(["inventory", filters], getInventory);

  const {
    data: inventory_overview,
    isLoading: inventory_overview_loading,
    refetch: inventory_overview_refetch,
  } = useQuery(["inventoryOverview"], getInventoryOverview);

  const getCompany = () => {
    return api.get("/companies/" + companyId);
  };
  const {
    data: company,
    isLoading: companyLoading,
    refetch: company_refetch,
  } = useQuery("company", getCompany);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getInventoryItems = () => {
    return api.get("/inventories/overviews");
  };

  const {
    isLoading: inventoriesItems_loading,
    data: inventoriesItems_data,
    refetch: inventoriesItems_refetch,
  } = useQuery(["inventoriesItems"], getInventoryItems);
  const getInventoryActivities = () => {
    return api.get(`/inventory-activities`, {
      params: {
        product: productId,
      },
    });
  };

  const {
    isLoading: inventoriesAct_loading,
    data: inventoriesAct_data,
    refetch: inventoriesAct_refetch,
  } = useQuery(["inventoriesActivities", productId], getInventoryActivities);

  const columns2 = [
    {
      title: "Activity Type",
      dataIndex: "activityType",
      key: "activityType",
    },
    {
      title: "Product Quantity",
      dataIndex: "productQty",
      key: "productQty",
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
    },
    {
      title: "Issued Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value) => <p>{moment(value).format("YYYY-MM-DD")}</p>,
    },
  ];

  const columns = [
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      render: (product) => (
        <>
          <Collapse
            bordered={false}
            style={{
              background: "transparent",
            }}
          >
            <Panel
              showArrow={false}
              header={
                <div
                  onClick={() => {
                    setProductId(product._id);
                  }}
                >
                  <span>{product?.name}</span>
                </div>
              }
            >
              <Table
                size="small"
                columns={columns2}
                dataSource={inventoriesAct_data?.data?.docs}
                className="fiscal-table"
              />
            </Panel>
          </Collapse>
        </>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "productQty",
      key: "productQty",
      render: (productQty) => <span>{`${productQty}`}</span>,
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      render: (rate) => <span>{`${rate}`}</span>,
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (totalAmount) => <span>{`${totalAmount}`}</span>,
    },
  ];

  const onChange = (value) => {
    console.log(`selected ${value}`);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  //bill types

  const billTypes = [
    "Sales",
    "Purchase",
    "Sales Return",
    "Purchase Return",
    "Others",
  ];

  return (
    <>
      <div className="main-container">
        <div>
          <span
            className="main-title link-element"
            onClick={() => {
              navigate("/dashboard/companies");
            }}
          >
            Company/
          </span>
          <span
            className="main-subtitle link-element"
            onClick={() => {
              navigate(-1);
            }}
          >
            {company?.data.name} /
          </span>
          <span className="main-subtitle">inventory</span>
        </div>
        <Row
          style={{
            justifyContent: "center",
            gap: "2rem",
            position: "relative",
          }}
        >
          {/* dropdown here  */}
          <div
            style={{
              height: "fit-content",
              position: "absolute",
              right: "0",
            }}
          >
            <Select
              showSearch
              placeholder="Select Fiscal Year"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={[
                {
                  value: "78/79",
                  label: "78/79",
                },
                {
                  value: "77/78",
                  label: "77/78",
                },
                {
                  value: "76/77",
                  label: "76/77",
                },
                {
                  value: "75/76",
                  label: "75/76",
                },
                {
                  value: "74/75",
                  label: "74/75",
                },
                {
                  value: "73/74",
                  label: "73/74",
                },
                {
                  value: "72/73",
                  label: "72/73",
                },
                {
                  value: "71/72",
                  label: "71/72",
                },
              ]}
            />
          </div>
          <div
            style={{
              display: "flex",
              overflowX: "scroll",
              gap: "4rem",
              marginTop: "4rem",
            }}
          >
            {billTypes.map((items, i) => {
              return (
                <>
                  <Row key={i} className="mt-10">
                    <CountingCard
                      icon={
                        <img
                          height={66}
                          width={66}
                          src={Count}
                          alt="employee"
                        />
                      }
                      number={
                        inventoriesItems_data?.data.filter(
                          (item) => item._id === items
                        )[0]?.count
                      }
                    >
                      <h1>{items}</h1>
                      <Row
                        style={{
                          justifyContent: "space-between",
                        }}
                      >
                        <h3>Total Amount:</h3>
                        <h3>
                          {inventoriesItems_data?.data.filter(
                            (item) => item._id === items
                          )[0]?.totalAmount !== undefined
                            ? parseFloat(
                                inventoriesItems_data?.data.filter(
                                  (item) => item._id === items
                                )[0]?.totalAmount
                              ).toFixed(2)
                            : 0}
                        </h3>
                      </Row>
                      <Row
                        style={{
                          justifyContent: "space-between",
                        }}
                      >
                        <h3>Total Quantity:</h3>
                        <h3>
                          {inventoriesItems_data?.data.filter(
                            (item) => item._id === items
                          )[0]?.totalQty !== undefined
                            ? parseInt(
                                inventoriesItems_data?.data.filter(
                                  (item) => item._id === items
                                )[0]?.totalQty
                              )
                            : 0}
                        </h3>
                      </Row>
                    </CountingCard>
                  </Row>
                </>
              );
            })}
          </div>
        </Row>
        <Row
          className="content-apart"
          style={{
            marginTop: "15px",
          }}
        >
          <h1 className="main-subtitle">Inventory</h1>
          <div
            style={{
              marginBottom: "10px",
            }}
          >
            {!inventory_loading && (
              <Button
                style={{ marginRight: "10px" }}
                onClick={() => {
                  setGenerateReportVisible(true);
                }}
                type="primary"
              >
                Set Filters
              </Button>
            )}
            {!inventory_loading && (
              <Button
                onClick={() => {
                  const excel = new Excel();
                  excel
                    .addSheet(company?.data.name ?? "inventory")
                    .addColumns(
                      columns.map((e) => {
                        const temp = e;
                        if (e.key === "product") temp.render = (e) => e?.name;
                        else delete temp.render;
                        return temp;
                      })
                    )
                    .addDataSource(inventory_data?.data.docs)
                    .saveAs(`${company?.data.name}_inventory_report.xlsx`);
                }}
                type="primary"
              >
                Generate Report
              </Button>
            )}
          </div>
        </Row>
        <Table
          size="large"
          columns={columns}
          dataSource={inventory_data?.data.docs}
          style={{
            marginTop: "10px",
          }}
          className="fiscal-table"
        />
        {isGenerateReportVisible && (
          <GenerateReportModal
            form={filterForm}
            visible={isGenerateReportVisible}
            handleCancel={() => {
              setGenerateReportVisible(false);
            }}
            handleReset={() => {
              filterForm.resetFields();
              setFilters({});
            }}
            handleOk={() => {
              filterForm.validateFields().then((values) => {
                if ("updatedAt>" in values)
                  values["updatedAt>"] = moment(values["updatedAt>"]).format(
                    "YYYY-MM-DD"
                  );
                if ("updatedAt<" in values)
                  values["updatedAt<"] = moment(values["updatedAt<"]).format(
                    "YYYY-MM-DD"
                  );
                if ("vendorWise" in values) delete values.vendorWise;
                setFilters(values);
              });
              setGenerateReportVisible(false);
            }}
          />
        )}
      </div>
    </>
  );
};

export default InventoryPage;
