export const Districts = [
    {
        label: "Bhojpur District",
        value: "भोजपुर",
        id: 1,
    },
    { label: "Dhankuta District", value: "धनकुटा", id: 2 },
    {
        label: "Ilam District",
        value: "इलाम",
        id: 3,
    },
    {
        label: "Jhapa District",
        value: "झापा",
        id: 4,
    },

    { label: "Khotang District", value: "खोटाङ", id: 5 },

    { label: "Morang District", value: "मोरङ", id: 6 },

    { label: "Okhaldhunga District", value: "ओखल्ढुङ्गा", id: 7 },

    { label: "Panchthar District", value: "पाँच्थर", id: 8 },

    { label: "Sankhuwasabha District", value: "संखुवासभा", id: 9 },

    { label: "Solukhumbu District", value: "सोलुखुम्बु", id: 10 },

    { label: "Sunsari District", value: "सुनसरी ", id: 11 },

    { label: "Taplejung District", value: "ताप्लेजुङ", id: 12 },

    { label: "Terhathum District", value: "तेह्रथुम", id: 13 },

    { label: "Udayapur District", value: "उदयपुर", id: 14 },

    { label: "Bara District", value: "बारा", id: 15 },

    { label: "Dhanusha District", value: "धनुषा", id: 16 },

    { label: "Mahottari District", value: "महोत्तरी", id: 17 },

    { label: "Parsa District", value: "झापा", id: 18 },

    { label: "Rautahat District", value: "रौतहट", id: 19 },

    { label: "Saptari District", value: "सप्तरी", id: 20 },

    { label: "Sarlahi District", value: "सर्लाही", id: 21 },

    { label: "Siraha District", value: "सिराहा", id: 22 },

    { label: "Bhaktapur District", value: "भक्तपुर", id: 23 },

    { label: "Chitwan District", value: "चितवन", id: 24 },

    { label: "Dhading District", value: "धादिङ", id: 25 },

    { label: "Dolakha District", value: "डोलाखआ", id: 26 },

    { label: "Kathmandu District", value: "काठमाडौं", id: 27 },

    { label: "Kavrepalanchok District", value: "काभ्रेपलान्चोक", id: 28 },

    { label: "Lalitpur District", value: "ललितपुर", id: 29 },

    { label: "Makwanpur District", value: "मकवानपुर", id: 30 },

    { label: "Nuwakot District", value: "नुवाकोट", id: 31 },

    { label: "Ramechhap District", value: "रामेछाप", id: 32 },

    { label: "Rasuwa District", value: "रसुवा", id: 33 },

    { label: "Sindhuli District", value: "सिन्धुली", id: 34 },

    { label: "Sindhupalchok District", value: "सिन्धुपाल्चोक", id: 35 },

    { label: "Baglung District", value: "बाग्लुङ", id: 36 },

    { label: "Gorkha District", value: "गोरखा", id: 37 },

    { label: "Kaski District", value: "कास्की", id: 38 },

    { label: "Lamjung District", value: "लम्जुङ", id: 39 },

    { label: "Manang District", value: "मनाङ", id: 40 },

    { label: "Mustang District", value: "मुस्ताङ", id: 41 },

    { label: "Myagdi District", value: "म्याग्दी", id: 42 },

    { label: "Nawalparasi District", value: "नवलपरासी (बसुप)", id: 43 },

    { label: "Parbat District", value: "पर्वत", id: 44 },

    { label: "Syangja District", value: "स्याङ्जा", id: 45 },

    { label: "Tanahun District", value: "तनहुँँ", id: 46 },

    { label: "Arghakhanchi District", value: "अर्घाखाँची", id: 47 },

    { label: "Kapilvastu District", value: "कपिलवस्तु", id: 48 },

    { label: "Parasi District", value: "परसि", id: 49 },

    { label: "Rupandehi District", value: "रुपन्देही", id: 50 }, // 50

    { label: "Gulmi District", value: "गुल्मी", id: 51 },

    { label: "Palpa District", value: "पाल्पा", id: 52 },

    { label: "Dang District", value: "दाङ", id: 53 },

    { label: "Pyuthan District", value: "प्यूठान", id: 54 },

    { label: "Rolpa District", value: "रोल्पा", id: 55 },

    { label: "Eastern Rukum District", value: "रुकुम पूर्व", id: 56 },

    { label: "Banke District", value: "बाँके", id: 57 },

    { label: "Bardiya District", value: "बर्दिया", id: 58 },

    { label: "Salyan District", value: "सल्यान", id: 59 },

    { label: "Dolpa District", value: "डोल्पा", id: 60 },

    { label: "Humla District", value: "हुम्ला", id: 61 },

    { label: "Jumla District", value: "जुम्ला", id: 62 },

    { label: "Kalikot District", value: "कालिकोट", id: 63 },

    { label: "Mugu District", value: "मुगू", id: 64 },

    { label: "Surkhet District", value: "सुर्खेत", id: 65 },

    { label: "Dailekh District", value: "दैलेख", id: 66 },

    { label: "Jajarkot District", value: "जाजरकोट", id: 67 },

    { label: "Western Rukum District", value: "रुकुम पश्चिम", id: 68 },

    { label: "Kailali District", value: "कैलाली", id: 69 },

    { label: "Achham District", value: "अछाम", id: 70 },

    { label: "Doti District", value: "डोटी", id: 71 },

    { label: "Bajhang District", value: "बझाङ", id: 72 },

    { label: "Bajura District", value: "बाजुरा", id: 73 },

    { label: "Kanchanpur District", value: "कंचनपुर", id: 74 },

    { label: "Dadeldhura District", value: "डदेल्धुरा", id: 75 },

    { label: "Baitadi District", value: "बैतदी", id: 76 },

    {
        label: "Darchula District",
        value: "डार्चुला",
        id: 77,
    },
];