import React from "react";
import { Row, Col } from "antd";
import fb from "../../assets/images/fb.png";
import google from "../../assets/images/google.png";
import { BASEURL } from "../../constant/Url.js";
function SocialLogin() {
  return (
    <div>
      <Row>
        <Col span={11}>
          <a href={`${BASEURL}/connect/google`}>
            <button
              style={{
                cursor: "pointer",
              }}
              className="social-button"
            >
              <img src={google} alt="google logo" /> Login With Google
            </button>
          </a>
        </Col>
        <Col span={1} />
        <Col span={11}>
          <a href={`${BASEURL}/connect/facebook`}>
            <button
              style={{
                cursor: "pointer",
              }}
              className="social-button"
            >
              <img src={fb} alt="fb logo" />
              Login With Facebook
            </button>
          </a>
        </Col>
      </Row>
    </div>
  );
}

export default SocialLogin;
