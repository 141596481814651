import React from "react";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";

function PageSearch(props) {
  return (
    <>
      <div style={{ marginRight: "5px" }} className="s-box">
        <SearchIcon className="s-icon" />
        <input className="contact-search" {...props} style={{ width: 200 }} />
      </div>
    </>
  );
}

export default PageSearch;
