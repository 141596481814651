import React, { useState } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  DatePicker,
  Select,
  Row,
  Tabs,
  Switch,
  Collapse,
} from "antd";
import DebounceSelect from "../../../components/common/DebounceSelect";
import { FaPlus, FaMinusCircle } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { api } from "../../../services/Api";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import { useQuery, useMutation } from "react-query";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
const { TabPane } = Tabs;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 20,
    },
    sm: {
      span: 14,
    },
  },
};

const { Option } = Select;
const { Panel } = Collapse;

function TaskForm({
  isModalVisible,
  handleCancel,
  handleOk,
  form,
  loading,
  initialValues,
  okText,
  headingText,
}) {
  const [showTemplateFilled, setShowTemplateFilled] = useState(false);
  const navigate = useNavigate();
  const getTaskTemplate = () => {
    return api.get("/template-tasks");
  };

  const {
    isLoading: template_loading,
    data: template_data,
    refetch: template_refetch,
  } = useQuery(["template"], getTaskTemplate);

  const removeTemplate = (id) => {
    return api.delete(`/template-tasks/${id}`);
  };

  const { mutate: template_delete_mutate, isLoading: template_delete_loading } =
    useMutation(removeTemplate, {
      onSuccess: () => {
        template_refetch();
        setShowTemplateFilled(false);
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure ?",
      text: "You want to delete this template",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Ok`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        template_delete_mutate(id);
        template_refetch();
      }
    });
  };

  const getUsers = async (queryString) => {
    return api
      .get("/users", {
        params: {
          firstName: encodeURI(`/${queryString}/i`),
        },
      })
      .then((body) =>
        body?.data?.docs?.map((user) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user._id,
        }))
      );
  };

  const getCompany = async (queryString) => {
    return api
      .get("/companies", {
        params: {
          name: encodeURI(`/${queryString}/i`),
        },
      })
      .then((body) =>
        body.data.docs.map((company) => ({
          label: `${company.name}`,
          value: company._id,
        }))
      );
  };
  if (initialValues) {
    initialValues.dueDate = moment(initialValues.dueDate);
    const modules = initialValues.modules.map((e) => {
      return {
        ...e,
        dueDate: moment(e.dueDate),
      };
    });
    initialValues.modules = modules;
  }

  const onChange = (key) => {
    console.log(key);
  };
  return (
    <Modal
      maskClosable={false}
      title={headingText}
      visible={isModalVisible}
      onCancel={handleCancel}
      okText="Create"
      cancelText="Cancel"
      className="global-modal"
      width={"30vw"}
      style={{ background: "white", top: "0px", float: "right" }}
      footer={[
        <Button
          loading={loading}
          key="submit"
          type="primary"
          onClick={handleOk}
        >
          {okText}
        </Button>,
      ]}
    >
      <div className="form-wrapper">
        <Tabs>
          <TabPane tab="Create New" key="Create New">
            <Form initialValues={initialValues} form={form} layout="vertical">
              <Form.Item
                label="Task Title"
                style={{
                  width: "100%",
                }}
                name="title"
                required
                rules={[
                  {
                    required: true,
                    message: "Please enter title of task!",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  placeholder="Task Title"
                />
              </Form.Item>
              <Form.Item
                label="Task Description"
                name="description"
                required
                rules={[
                  {
                    required: true,
                    message: "Please enter task's description !",
                  },
                ]}
              >
                <TextArea rows={4} placeholder="Description" />
              </Form.Item>
              <Form.Item
                label="Task Priority"
                name="priority"
                required
                rules={[
                  {
                    required: true,
                    message: "Please select priority !",
                  },
                ]}
              >
                <Select placeholder="Task Priority">
                  <Option value="Low">Low</Option>
                  <Option value="Medium">Medium</Option>
                  <Option value="High">High</Option>
                </Select>
              </Form.Item>

              <Form.Item label="Company" name="company">
                <DebounceSelect
                  className="mb-10"
                  fetchOptions={getCompany}
                  placeholder="Company"
                />
              </Form.Item>

              {/* <Form.Item label="User" name="user">
                <DebounceSelect
                  className="mb-10"
                  fetchOptions={getUsers}
                  placeholder="User"
                />
              </Form.Item> */}

              <Form.Item label="Start Date" name="startDate">
                <DatePicker placeholder="Select Start Date" />
              </Form.Item>
              <Form.Item label="Due Date" name="dueDate">
                <DatePicker placeholder="Select Due Date" />
              </Form.Item>

              <Form.List name="modules">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field) => (
                      <Row
                        // style={{ justifyContent: "center" }}
                        key={field.key}
                        // align="baseline"
                      >
                        <Form.Item>
                          <Form.Item name={[field.name, "title"]}>
                            <TextArea
                              className="text-text"
                              rows={2}
                              placeholder="Module Title"
                            />
                          </Form.Item>
                        </Form.Item>
                        <FaMinusCircle onClick={() => remove(field.name)} />
                      </Row>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<FaPlus />}
                      >
                        Add Module
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <Form.Item
                label="Mark as Template"
                name="markAsTemplate"
                valuePropName="checked"
              >
                <Switch checked />
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane tab="From Template" key="From Template">
            {!showTemplateFilled && (
              <div>
                {template_data?.data?.docs?.map((td, i) => {
                  return (
                    <>
                      <Row
                        onClick={() => {
                          form.setFieldsValue(td);
                          setShowTemplateFilled(true);
                        }}
                        className="temp-card"
                      >
                        <div
                          onClick={() => {
                            form.setFieldsValue(td);
                            setShowTemplateFilled(true);
                          }}
                        >
                          <h4>{td?.title}</h4>
                        </div>
                        <div>
                          <MdDelete
                            style={{
                              color: "red",
                            }}
                            size={20}
                            onClick={() => {
                              handleDelete(td._id);
                            }}
                          />
                        </div>
                      </Row>
                    </>
                  );
                })}
              </div>
            )}

            {showTemplateFilled && (
              <Form initialValues={initialValues} form={form} layout="vertical">
                <Form.Item
                  name="description"
                  required
                  label="Task Description"
                  rules={[
                    {
                      required: true,
                      message: "Please enter task's description !",
                    },
                  ]}
                >
                  <TextArea placeholder="Description" />
                </Form.Item>
                <Form.Item
                  name="priority"
                  required
                  label="Task Priority"
                  rules={[
                    {
                      required: true,
                      message: "Please select priority !",
                    },
                  ]}
                >
                  <Select placeholder="Task Priority">
                    <Option value="Low">Low</Option>
                    <Option value="Medium">Medium</Option>
                    <Option value="High">High</Option>
                  </Select>
                </Form.Item>

                <Form.Item label="Company" name="company">
                  <DebounceSelect
                    className="mb-10"
                    fetchOptions={getCompany}
                    placeholder="Company"
                  />
                </Form.Item>
                <Form.Item label="Start Date" name="startDate">
                  <DatePicker placeholder="Select Start Date" />
                </Form.Item>
                <Form.Item label="Due Date" name="dueDate">
                  <DatePicker placeholder="Select Due Date" />
                </Form.Item>

                <Form.List name="modules">
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map((field) => (
                        <Row
                          style={{ justifyContent: "start" }}
                          key={field.key}
                          align="baseline"
                        >
                          <Form.Item noStyle>
                            <Form.Item name={[field.name, "title"]}>
                              <TextArea
                                className="text-text"
                                rows={2}
                                placeholder="Module Title"
                              />
                            </Form.Item>
                          </Form.Item>
                          <FaMinusCircle onClick={() => remove(field.name)} />
                        </Row>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<FaPlus />}
                        >
                          Add Module
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form>
            )}
          </TabPane>
        </Tabs>
      </div>
    </Modal>
  );
}

export default TaskForm;
