import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BASEURL } from "../../constant/Url.js";
import showPwdImg from "./show-password.svg";
import hidePwdImg from "./hide-password.svg";
import Loading from "../common/Loading.jsx";

function LoginForm() {
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const login = async (data) => {
    try {
      setLoading(true);
      const res = await axios.post(`${BASEURL}/auth/local/login`, data);
      localStorage.setItem("token", res.data.access_token);
      const token = localStorage.getItem("token");

      if (token) {
        navigate("/dashboard");
        window.location.reload(); // force a refresh w/ the server
      }
    } catch (error) {
      const errorMessage = error.response.data.message;
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(login)}>
        <input
          type="email"
          name="identifier"
          id="email"
          placeholder="Email Address"
          {...register("identifier", { required: true })}
        />
        {errors.identifier?.type === "required" && (
          <span style={{ color: "red" }}> This field is required</span>
        )}

        <div style={{ position: "relative" }}>
          <input
            type={passwordShown ? "text" : "password"}
            name="password"
            id="password"
            placeholder="Password"
            {...register("password", { required: true, minLength: 6 })}
          />
          {errors.password?.type === "required" && (
            <span style={{ color: "red" }}>This field is required</span>
          )}
          <img
            src={passwordShown ? hidePwdImg : showPwdImg}
            onClick={() => setPasswordShown((prevState) => !prevState)}
            alt="password"
            className="passEye"
          />

          <p style={{ color: "red", marginTop: "1rem" }}>{error}</p>
        </div>
        <button type="submit" className="login-button">
          {loading ? <Loading isSmall /> : "Login"}
        </button>
      </form>
    </div>
  );
}

export default LoginForm;
