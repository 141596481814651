import React from "react";
import { Col, Row, Button, Avatar, Comment, Tooltip, Image } from "antd";
import moment from "moment";
import BarChart from "../../components/charts/BarChart";
import PieChart from "../../components/charts/PieChart";
import { api } from "../../services/Api";
import { useQuery } from "react-query";
import CountingCard from "../../components/common/CountingCard";
import Client from "../../assets/images/client.png";
import Employee from "../../assets/images/emp.png";
import Office from "../../assets/images/office.png";
import Task from "../../assets/images/task.png";
import { limit } from "../../utils/truncateString";
import { useNavigate } from "react-router-dom";
import feed2 from "../../assets/images/feedback.png";

function Main() {
  const navigate = useNavigate();

  const getFeeds = () => {
    return api.get("/feeds");
  };

  const {
    isLoading: feed_loading,
    data: feed_data,
    refetch: feed_refetch,
  } = useQuery("feed", getFeeds);

  const getTotalUsers = () => {
    return api.get("/users/count", {
      params: {
        "relation_role.slug": "user",
      },
    });
  };

  const {
    isLoading: count_users_loading,
    data: count_users,
    refetch: count_users_refetch,
  } = useQuery("TotalUsers", getTotalUsers);

  const getTotalEmp = () => {
    return api.get("/users/count", {
      params: {
        "relation_role.slug!": "user",
      },
    });
  };

  const {
    isLoading: count_Emp_loading,
    data: count_Emp,
    refetch: count_Emp_refetch,
  } = useQuery("TotalEmps", getTotalEmp);

  const getTotalTasks = () => {
    return api.get("/tasks/count");
  };

  const {
    isLoading: total_tasks_loading,
    data: total_tasks,
    refetch: total_tasks_refetch,
  } = useQuery("TotalTasks", getTotalTasks);

  const getTotalCompanies = () => {
    return api.get("/companies/count");
  };

  const {
    isLoading: total_companies_loading,
    data: total_companies,
    refetch: total_companies_refetch,
  } = useQuery("TotalCompanies", getTotalCompanies);

  const getGraphData = () => {
    return api.get("/tasks/dashboard/graph-data");
  };

  const {
    isLoading: graphData_loading,
    data: graphData_data,
    refetch: graphData_refetch,
  } = useQuery("totalGraphData", getGraphData);

  return (
    <div className="main-container">
      <h1 className="main-title">Dashboard Overview</h1>
      <div>
        <Row
          style={{
            justifyContent: "space-between",
          }}
        >
          <CountingCard
            icon={<img height={66} width={66} src={Employee} alt="employee" />}
            number={count_Emp?.data}
          >
            <h3>Total number of Employees</h3>
          </CountingCard>

          <CountingCard
            icon={<img height={66} width={66} src={Client} alt="client" />}
            number={count_users?.data}
          >
            <h3>Total number of Clients</h3>
          </CountingCard>

          <CountingCard
            icon={<img height={66} width={66} src={Task} alt="task" />}
            number={total_tasks?.data}
          >
            <h3>Total number of Tasks</h3>
          </CountingCard>

          <CountingCard
            icon={<img height={66} width={66} src={Office} alt="companies" />}
            number={total_companies?.data}
          >
            <h3>Total number of Companies</h3>
          </CountingCard>
        </Row>
      </div>

      <h1 className="main-subtitle">User's Overview</h1>
      <Row
        gutter={16}
        style={{ marginTop: "1rem" }}
        className="feed-responsive"
      >
        <Col span={10}>
          <div className="dashboard-card-2">
            <PieChart />
          </div>
        </Col>
        <Col span={14}>
          <div className="dashboard-card">
            <BarChart data={graphData_data?.data} />
          </div>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: "2rem" }}>
        <Col span={24}>
          <div className="feed-section">
            <h2
              className="main-subtitle"
              style={{
                marginLeft: "5px",
              }}
            >
              Feeds
            </h2>
            <hr />
            {feed_data?.data?.docs?.slice(0, 3)?.map((feed, i) => {
              return (
                <>
                  <div key={i}>
                    <Comment
                      avatar={
                        <Avatar
                          src={<Image src={feed?.featureImage?.url ?? feed2} />}
                          alt="feed"
                        />
                      }
                      content={
                        <p
                          style={{
                            paddingRight: "7px",
                            opacity: 0.8,
                            marginTop: "5px",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: limit(feed?.description, 160),
                          }}
                        ></p>
                      }
                      datetime={
                        <Tooltip>
                          <span
                            style={{
                              marginLeft: "5px",
                            }}
                          >
                            {moment(feed.createdAt).format(
                              "YYYY/MM/DD , h:mm a"
                            )}
                          </span>
                        </Tooltip>
                      }
                    />
                  </div>
                </>
              );
            })}
            <Button
              type="primary"
              onClick={() => {
                navigate(`/dashboard/feeds`);
              }}
              style={{
                marginLeft: "35%",
                marginTop: "2%",
                position: "sticky",
              }}
            >
              View all Feeds
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Main;
