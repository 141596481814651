import React, { useState } from "react";
import PageSearch from "../../../components/common/PageSearch";
import { Table, Button, Form, Row, Space, Dropdown, Menu } from "antd";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { IoEllipsisVertical } from "react-icons/io5";
import { useQuery, useMutation } from "react-query";
import { api } from "../../../services/Api";
import debounce from "lodash/debounce";
import ClientForm from "./MerchantForm";
import { FaUserPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { fetchUser } from "../../../services/User";
import Swal from "sweetalert2";
import { restriction } from "../../../constant/Restrictions";

function Merchants() {
  const [regexQuery, setRegexQuery] = useState("");
  const [addClientVisible, setAddClientVisible] = useState(false);
  const [editClientVisible, setEditClientVisible] = useState(false);
  const [clientForm] = Form.useForm();
  const [clientEditForm] = Form.useForm();
  const [editMerchant, setEditMerchant] = useState({});
  const navigate = useNavigate();
  const { isUserLoading, data: userData } = useQuery("user", fetchUser);
  const [params, setParams] = useState({
    sort: "-createdAt",
    page: 1,
    limit: 10,
    skip: 0,
  });

  const handleEditMerchant = () => {
    clientEditForm.resetFields();
    setEditMerchant({});
    setEditClientVisible(false);
  };

  const showEditMerchant = (data) => {
    setEditMerchant(data);
    setEditClientVisible(true);
  };

  const patchMerchant = (data) => {
    return api.patch(`/merchants/${editMerchant._id}`, data);
  };

  const { mutate: merchant_patch_mutate, isLoading: merchant_patch_loading } =
    useMutation(patchMerchant, {
      onSuccess: () => {
        client_refetch();
        clientForm.resetFields();
        setEditClientVisible(false);
        setEditMerchant({});
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });

  const handleMerchantEditOk = () => {
    clientEditForm
      .validateFields()
      .then((values) => {
        merchant_patch_mutate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const removeMerchant = (data) => {
    return api.delete(`/merchants/${data._id}`);
  };
  const { mutate: merchant_delete_mutate, isLoading: merchant_delete_loading } =
    useMutation(removeMerchant, {
      onSuccess: () => {
        client_refetch();
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });

  const handleMerchantDelete = (data) => {
    Swal.fire({
      title: "Are you sure ?",
      text: "You want to delete this merchant.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Ok`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        merchant_delete_mutate(data);
      }
    });
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Contact",
      dataIndex: "phone",
      key: "phone",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => (
        <a
          target="_blank"
          rel="noreferrer"
          href={`mailto:${text}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <div>
          {!restriction[userData?.data.role.slug].includes(
            "change_inventory"
          ) && (
            <Dropdown
              overlay={
                <Menu mode="horizontal">
                  <Menu.Item
                    key="one"
                    className="edit-btn"
                    onClick={() => showEditMerchant(record)}
                    icon={
                      <MdModeEdit style={{ color: "blue", fontSize: "20px" }} />
                    }
                  >
                    Edit
                  </Menu.Item>

                  <Menu.Item
                    key="two"
                    className="delete-btn"
                    onClick={() => handleMerchantDelete(record)}
                    icon={
                      <MdDelete style={{ color: "red", fontSize: "20px" }} />
                    }
                  >
                    Delete
                  </Menu.Item>
                </Menu>
              }
            >
              <Space>
                <IoEllipsisVertical
                  style={{
                    cursor: "pointer",
                    color: "#00ABC5",
                  }}
                  size={20}
                />
              </Space>
            </Dropdown>
          )}
        </div>
      ),
    },
  ];
  const getClient = () => {
    return api.get("/merchants", {
      params: {
        "relation_role.slug": "user",
        firstName: encodeURI(`/${regexQuery}/i`),
        ...params,
      },
    });
  };
  const {
    isLoading: merchant_loading,
    data,
    refetch: client_refetch,
  } = useQuery(["allmerchant", regexQuery, params], getClient);
  const postClient = (data) => {
    return api.post("/merchants", data);
  };
  console.log(data);
  const { mutate: client_add_mutate, isLoading: client_add_loading } =
    useMutation(postClient, {
      onSuccess: () => {
        client_refetch();
        clientForm.resetFields();
        setAddClientVisible(false);
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });
  const handleClientAddCancel = () => {
    clientForm.resetFields();
    setAddClientVisible(false);
  };
  const handleClientAddOk = () => {
    clientForm
      .validateFields()
      .then((values) => {
        client_add_mutate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <>
      <div className="main-container">
        <Row className="content-apart mb-10">
          <h1
            style={{
              cursor: "pointer",
            }}
            className="main-title"
          >
            Merchants
          </h1>
          <Row>
            <PageSearch
              onChange={(e) => {
                debounce(setRegexQuery(e.target.value), 800);
              }}
              placeholder="Search for merchants"
            />
            {!restriction[userData?.data.role.slug].includes(
              "change_inventory"
            ) && (
              <Button
                style={{ borderRadius: "0.5em" }}
                type="primary"
                onClick={() => {
                  setAddClientVisible(true);
                }}
              >
                <FaUserPlus size={20} />
              </Button>
            )}
          </Row>
        </Row>

        <Table
          pagination={{
            current: params.page,
            total: data?.data.totalDocs,
            onChange: (page) => {
              setParams({
                ...params,
                page,
                skip: (page - 1) * params.limit,
              });
            },
          }}
          size="small"
          loading={merchant_loading}
          className="fiscal-table"
          columns={columns}
          dataSource={data?.data.docs}
        />
      </div>
      {addClientVisible && (
        <ClientForm
          isModalVisible={addClientVisible}
          form={clientForm}
          okText="Create"
          handleCancel={handleClientAddCancel}
          handleOk={handleClientAddOk}
          loading={client_add_loading}
          headingText=""
        />
      )}
      {editClientVisible && (
        <ClientForm
          isModalVisible={editClientVisible}
          form={clientEditForm}
          okText="Update"
          handleCancel={handleEditMerchant}
          handleOk={handleMerchantEditOk}
          loading={merchant_patch_loading}
          initialValues={editMerchant}
          headingText=""
        />
      )}
    </>
  );
}

export default Merchants;
