import React, { useState, useEffect } from "react";
import PageSearch from "../../../components/common/PageSearch";
import { Button, Table, Form, Row, Tooltip, Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/common/Loading";
import debounce from "lodash/debounce";
import { CgExtensionAdd } from "react-icons/cg";
import { useQuery, useMutation } from "react-query";
import CompanyForm from "./CompanyForm";
import { stringToColour } from "../../../utils/getProfileColor";
import { fetchUser } from "../../../services/User";
import { restriction } from "../../../constant/Restrictions";
import { api } from "../../../services/Api";
import { limit } from "../../../utils/truncateString";
import moment from "moment";
import FetchUnpostedBills from "../../../components/common/FetchUnpostedBills";

function Companies() {

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [params, setParams] = useState({
    sort: "-createdAt",
    page: 1,
    limit: 15,
    skip: 0,
  });
  const { isLoading: isUserLoading, data: userData } = useQuery(
    "user",
    fetchUser
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (_, record) => (
        <span>
          <Tooltip title={<div>{`${record?.name}`}</div>} placement="top">
            {record?.avatar?.url ? (
              <Avatar size="small" src={record?.avatar?.url} />
            ) : (
              <Avatar
                className="capital"
                size="small"
                style={{ backgroundColor: stringToColour(record?.name) }}
              >
                {record?.name?.[0] ?? "U"}
              </Avatar>
            )}
          </Tooltip>
          &nbsp; &nbsp;
          {limit(record.name, 50)}
        </span>
      ),
    },
    {
      title: "नेपाली नाम",
      dataIndex: "nepaliName",
      key: "nepaliName",
      render: (text) => <span>{limit(text, 50)}</span>,
    },
    {
      width: "13vw",
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => (
        <a
          target="_blank"
          rel="noreferrer"
          href={`mailto:${text}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {limit(text, 30)}
        </a>
      ),
    },
    {
      title: "Unposted bill",
      dataIndex: "UnpostedBill",
      key: "UnpostedBill",
      render: (_, record) => {
        // getUnpostedBill(record._id);
        return (
          <>
            <FetchUnpostedBills
              url="/bills"
              id={record._id}

            />
          </>
        );
      },
    },
  ];

  const [regexQuery, setRegexQuery] = useState("");

  useEffect(() => {
    if (restriction[userData?.data.role.slug]?.includes("view_all_company")) {
      setParams({ ...params, assignee: userData.data.id });
    }
  }, [userData.data.id]);

  const getCompanies = () => {
    return api.get("/companies", {
      params: {
        ...params,
        name: encodeURI(`/${regexQuery}/i`),
      },
    });
  };


  const createCompany = (data) => {
    return api.post("/companies", data);
  };

  const [isModalVisible, setModalVisible] = useState(false);
  const handleCancel = () => {
    form.resetFields();
    setModalVisible(false);
  };
  const handleCompanyCreate = (values) => {
    company_add_mutate(values);
  };
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        handleCompanyCreate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const { mutate: company_add_mutate, isLoading: company_add_loading } =
    useMutation(createCompany, {
      onSuccess: () => {
        company_refetch();
        form.resetFields();
        setModalVisible(false);
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });

  const {
    isLoading: company_loading,
    data: company_data,
    refetch: company_refetch,
  } = useQuery(["allcompany", regexQuery, params], getCompanies);

  const updateCompanypic = (data) => {
    const payload = {
      avatar: data.avatar,
    };
    const formData = new FormData();
    formData.append("avatar", data.avatar);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return api.patch(
      `/companies/${data._id}/update-avatar-pic`,
      formData,
      config,
      payload
    );
  };

  const { mutate: update_profile_mutate, isLoading: update_profile_loading } =
    useMutation(updateCompanypic, {
      onSuccess: () => {
        company_refetch();
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });

  const uploadImage = (avatar) => {
    update_profile_mutate({ avatar: avatar });
  };

  return (
    <>
      <div className="main-container">
        <Row className="content-apart mb-10">
          <h1 className="main-title">Companies</h1>
          <Row>
            <PageSearch
              onChange={(e) => {
                debounce(setRegexQuery(e.target.value), 800);
              }}
              placeholder="Search for company"
            />
            {!restriction[userData?.data.role.slug].includes(
              "change_company"
            ) && (
              <Button
                style={{ borderRadius: "0.5em" }}
                type="primary"
                onClick={() => {
                  setModalVisible(true);
                }}
              >
                <CgExtensionAdd size={20} />
              </Button>
            )}
          </Row>
        </Row>

        <Table
          style={{ overflowX: "scroll" }}
          loading={company_loading && isUserLoading}
          pagination={{
            current: params.page,
            total: company_data?.data.totalDocs,
            pageSize: 15,
            onChange: (page) => {
              setParams({
                ...params,
                page,
                skip: (page - 1) * params.limit,
              });
            },
          }}
          size="small"
          // scroll={{ y: "60vh" }}
          onRow={(record, _) => {
            return {
              onClick: (_) => {
                navigate(`${record._id}`);
              },
            };
          }}
          className="fiscal-table"
          columns={columns}
          dataSource={company_data?.data.docs}
        />
      </div>
      {isModalVisible && (
        <CompanyForm
          isModalVisible={isModalVisible}
          handleCancel={handleCancel}
          handleOk={handleOk}
          form={form}
          loading={company_add_loading}
          okText="Create"
        />
      )}
    </>
  );
}

export default Companies;
