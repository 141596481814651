import { Modal, Button, Form } from "antd";
import React from "react";
import DebounceSelect from "../../../components/common/DebounceSelect";
import { api } from "../../../services/Api";

function AssignEmployeeForm({
  visible,
  handleCancel,
  handleOk,
  form,
  exclude,
}) {
  const getUsers = async (queryString) => {
    return api
      .get("/users", {
        params: {
          "userName!": exclude.join(","),
          "relation_role.slug!": "user",
          firstName: encodeURI(`/${queryString}/i`),
        },
      })
      .then((body) =>
        body.data.docs.map((user) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user._id,
        }))
      );
  };
  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      okText="Create"
      cancelText="Cancel"
      width={"30vw"}
      style={{ background: "white", top: "0px" }}
      className="global-modal"
      footer={[
        <Button key="submit" type="primary" onClick={handleOk}>
          Assign
        </Button>,
      ]}
    >
      <div className="form-wrapper">
        <Form form={form} layout="horizontal">
          <Form.Item
            rules={[
              {
                required: true,
                message: "Assignee is required!",
              },
            ]}
            required
            name="assignee"
          >
            <DebounceSelect fetchOptions={getUsers} placeholder="Assignee" />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}

export default AssignEmployeeForm;
