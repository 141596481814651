import { Row } from "antd";
import React from "react";

export default function Loading({ isSmall }) {
  return (
    <Row className="content-center">
      <div className={isSmall ? "small-spinner" : "loading-spinner"}></div>
    </Row>
  );
}
