import React from "react";
import { Button, Modal, Form, Input, Select } from "antd";
import { FaInfoCircle } from "react-icons/fa";
import { api } from "../../../services/Api";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 20,
    },
    sm: {
      span: 14,
    },
  },
};

function MerchantForm({
  isModalVisible,
  handleCancel,
  handleOk,
  form,
  loading,
  initialValues,
  okText,
  headingText,
}) {
  return (
    <Modal
      maskClosable={false}
      title={headingText}
      visible={isModalVisible}
      onCancel={handleCancel}
      okText="Create"
      cancelText="Cancel"
      width={"30vw"}
      style={{ background: "white", top: "0px" }}
      className="global-modal"
      footer={[
        <Button
          loading={loading}
          key="submit"
          type="primary"
          onClick={handleOk}
        >
          {okText}
        </Button>,
      ]}
    >
      <div style={{ padding: "1em", height: "84vh" }}>
        <Form
          initialValues={initialValues}
          // {...formItemLayout}
          form={form}
          layout="vertical"
        >
          <Form.Item
            label="Prefix Name"
            name="prefixName"
            required
            rules={[
              {
                required: true,
                message: "Please Enter Prefix Name!",
              },
            ]}
          >
            <Input placeholder="Prefix Name" />
          </Form.Item>
          <Form.Item
            label="Merchant First Name"
            name="firstName"
            required
            rules={[
              {
                required: true,
                message: "Please Enter First Name!",
              },
            ]}
          >
            <Input placeholder="First Name" />
          </Form.Item>
          <Form.Item
            label="Merchant Last Name"
            name="lastName"
            required
            rules={[
              {
                required: true,
                message: "Please enter Last Name!",
              },
            ]}
          >
            <Input placeholder="Last Name" />
          </Form.Item>
          <Form.Item
            label="Merchant's Email"
            name="email"
            required
            rules={[
              {
                required: true,
                message: "Please enter merchant's email!",
              },
              {
                type: "email",
                message: "Please enter a valid email address !",
              },
            ]}
          >
            <Input type="email" placeholder="Merchant's Email" />
          </Form.Item>
          <Form.Item
            label="Merchant's Phone Number"
            name="phone"
            required
            rules={[
              {
                required: true,
                message: "Please enter merchant's phone!",
              },
            ]}
          >
            <Input type="tel" placeholder="Merchant's Phone Number" />
          </Form.Item>
          {/* <Form.Item
          label="Type"
          name="merchantType"
          required
          tooltip={{
            title: "Enter merchant's type",
            icon: <FaInfoCircle />,
          }}
          rules={[
            {
              required: true,
              message: "Please merchant's type !",
            },
          ]}
        >
          <Select>
            <Select.Option value="Vendor">Vendor</Select.Option>
            <Select.Option value="Customer">Customer</Select.Option>
          </Select>
        </Form.Item> */}
          <Form.Item
            label="Merchant's Pan Number"
            name="pan"
            required
            rules={[
              {
                required: true,
                message: "Please enter merchant's Pan!",
              },
            ]}
          >
            <Input type="number" placeholder="Merchant's Pan Number" />
          </Form.Item>
          <Form.Item
            label="Merchant's Address"
            name="address"
            required
            rules={[
              {
                required: true,
                message: "Please enter address!",
              },
            ]}
          >
            <Input type="text" placeholder="Merchant's address" />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}

export default MerchantForm;
