import React, { useState } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  DatePicker,
  Select,
  Row,
  Tabs,
  Switch,
} from "antd";
import { FaPlus, FaMinusCircle } from "react-icons/fa";
import { api } from "../../../services/Api";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
const { TabPane } = Tabs;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 20,
    },
    sm: {
      span: 14,
    },
  },
};

const { Option } = Select;

function TaskEditForm({
  isModalVisible,
  handleCancel,
  handleOk,
  form,
  loading,
  initialValues,
  okText,
  headingText,
}) {
  const [showTemplateFilled, setShowTemplateFilled] = useState(false);
  const getTaskTemplates = async (queryString) => {
    return api.get("/template-tasks", {}).then((body) =>
      body.data.docs.map((template) => ({
        label: `${template.title}`,
        value: JSON.stringify(template),
      }))
    );
  };
  const getCompany = async (queryString) => {
    return api
      .get("/companies", {
        params: {
          name: encodeURI(`/${queryString}/i`),
        },
      })
      .then((body) =>
        body?.data?.docs?.map((company) => ({
          label: `${company?.name}`,
          value: company?._id,
        }))
      );
  };
  if (initialValues) {
    initialValues.dueDate = moment(initialValues?.dueDate);
    initialValues.startDate = moment(initialValues?.startDate);
    const modules = initialValues?.modules?.map((e) => {
      return {
        ...e,
        dueDate: moment(e?.dueDate),
        startDate: moment(e?.startDate),
      };
    });
    initialValues.modules = modules;
  }
  return (
    <Modal
      maskClosable={false}
      title={headingText}
      visible={isModalVisible}
      onCancel={handleCancel}
      okText="Create"
      cancelText="Cancel"
      className="global-modal"
      width={"30vw"}
      style={{ background: "white", top: "0px", float: "right" }}
      footer={[
        <Button
          loading={loading}
          key="submit"
          type="primary"
          onClick={handleOk}
        >
          {okText}
        </Button>,
      ]}
    >
      <div className="form-wrapper">
        <Form initialValues={initialValues} form={form} layout="vertical">
          <Form.Item
            label="Task Title"
            style={{
              width: "100%",
            }}
            name="title"
            required
            rules={[
              {
                required: true,
                message: "Please enter title of task!",
              },
            ]}
          >
            <Input
              style={{
                width: "100%",
              }}
              placeholder="Task Title"
            />
          </Form.Item>
          <Form.Item label="Task Description" name="description">
            <TextArea rows={4} placeholder="Description" />
          </Form.Item>
          <Form.Item
            label="Task Priority"
            name="priority"
            required
            rules={[
              {
                required: true,
                message: "Please select priority !",
              },
            ]}
          >
            <Select placeholder="Task Priority">
              <Option value="Low">Low</Option>
              <Option value="Medium">Medium</Option>
              <Option value="High">High</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Start Date" name="startDate">
            <DatePicker placeholder="Select Start Date" />
          </Form.Item>
          <Form.Item label="Due Date" name="dueDate">
            <DatePicker placeholder="Select Due Date" />
          </Form.Item>

          <Form.List name="modules">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields?.map((field) => (
                  <Row
                    style={{ justifyContent: "center" }}
                    key={field.key}
                    align="baseline"
                  >
                    <Form.Item noStyle>
                      <Form.Item name={[field?.name, "title"]}>
                        <TextArea
                          className="text-text"
                          rows={2}
                          placeholder="Module Title"
                        />
                      </Form.Item>
                    </Form.Item>
                    <FaMinusCircle onClick={() => remove(field.name)} />
                  </Row>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<FaPlus />}
                  >
                    Add Module
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item
            label="Mark as Template"
            name="markAsTemplate"
            valuePropName="checked"
          >
            <Switch checked />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}

export default TaskEditForm;
