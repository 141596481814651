import { Modal } from "antd";
import React from "react";

function EditDialog({
  Title,
  children,
  handleEditCancel,
  evisible,
  showEditModal,
}) {
  return (
    <>
      <Modal
        maskClosable={false}
        visible={evisible}
        title={Title}
        showModal={showEditModal}
        onCancel={handleEditCancel}
        className="global-modal"
        width={"30vw"}
        style={{ background: "white", top: "0px" }}
      >
        {children}
      </Modal>
    </>
  );
}

export default EditDialog;
