import React from "react";
import { api } from "../../services/Api";
import { useQuery } from "react-query";
import moment from "moment";
import { restriction } from "../../constant/Restrictions";
import { fetchUser } from "../../services/User";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function InvenChart() {
  //   const getGraphData = () => {
  //     return api.get(`/tasks/dashboard/graph-data`);
  //   };

  //   const {
  //     isLoading: graph_loading,
  //     data: graph_data,
  //     refetch: graph_refetch,
  //   } = useQuery("graphData", getGraphData);

  //   console.log(graph_data);

  const options = {
    responsive: true,

    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Monthly Task Progress Report",
      },
    },
  };

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: [10, 20, 30, 40, 50, 60, 70],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Dataset 2",
        data: [5, 15, 25, 35, 45, 55, 65],
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  return (
    <>
      <Bar height={100} options={options} data={data} />
    </>
  );
}

export default InvenChart;
