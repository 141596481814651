import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../../../services/Api";
import { IoEllipsisVertical } from "react-icons/io5";
import { FaEdit, FaTrash } from "react-icons/fa";
import Swal from "sweetalert2";
import {
  Row,
  Col,
  Button,
  Form,
  Avatar,
  Image,
  Dropdown,
  Space,
  Menu,
  Table,
} from "antd";
import { useQuery, useMutation } from "react-query";
import PageSearch from "../../../components/common/PageSearch";
import debounce from "lodash/debounce";
import { MdOutlineAdd } from "react-icons/md";
import feed from "../../../assets/images/feedback.png";
import { limit } from "../../../utils/truncateString";
import FeedForm from "./FeedForm";
import { restriction } from "../../../constant/Restrictions";
import { fetchUser } from "../../../services/User";
import moment from "moment";
import { MdModeEditOutline } from "react-icons/md";

const Feed = () => {
  const navigate = useNavigate();
  const [regexQuery, setRegexQuery] = useState("");
  const [addFeedVisible, setAddFeedVisible] = useState(false);
  const [feedForm] = Form.useForm();
  const { isUserLoading, data: userData } = useQuery("user", fetchUser);
  const [isFeedEditModalVisible, setIsFeedEditModalVisible] = useState(false);

  //const column data
  const columnData = [];

  //api call
  const getFeeds = () => {
    return api.get("/feeds", {
      params: {
        title: encodeURI(`/${regexQuery}/i`),
      },
    });
  };

  const {
    isLoading: feed_loading,
    data: feed_data,
    refetch: feed_refetch,
  } = useQuery(["feed", regexQuery], getFeeds);

  const postFeed = (payload) => {
    return api.post("/feeds", payload);
  };

  const { mutate: feed_add_mutate, isLoading: feed_add_loading } = useMutation(
    postFeed,
    {
      onSuccess: () => {
        feed_refetch();
        feedForm.resetFields();
        setAddFeedVisible(false);
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    }
  );

  const handleFeedAddCancel = () => {
    feedForm.resetFields();
    setAddFeedVisible(false);
  };

  const handleFeedCreate = (values) => {
    feed_add_mutate(values);
  };

  const handleFeedAddOk = () => {
    feedForm
      .validateFields()
      .then((values) => {
        handleFeedCreate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  ////////////upload image
  const [feedId, setFeedId] = useState();

  const updateFeedPic = (data) => {
    const payload = {
      featureImage: data.featureImage,
    };

    const formData = new FormData();
    formData.append("featureImage", data.featureImage);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    console.log("form data", formData);
    return api.patch(
      `/feeds/${feedId}/update-feature-img`,
      formData,
      config,
      payload
    );
  };

  const { mutate: update_feed_img_mutate, isLoading: update_feed_img_loading } =
    useMutation(updateFeedPic, {
      onSuccess: () => {
        feed_refetch();
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });

  const uploadImg = (featureImage) => {
    update_feed_img_mutate({ featureImage: featureImage });
  };

  const handleFeedEditCancel = () => {
    feedForm.resetFields();
    setIsFeedEditModalVisible(false);
  };
  const updateFeed = (data) => {
    return api.patch(`/feeds/${feedId}`, data);
  };
  const { mutate: update_feed_mutate, isLoading: update_feed_loading } =
    useMutation(updateFeed, {
      onSuccess: () => {
        feed_refetch();
        feedForm.resetFields();
        setIsFeedEditModalVisible(false);
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });
  const handleFeedEditOk = () => {
    feedForm
      .validateFields()
      .then((values) => {
        update_feed_mutate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const removeFeed = (feedId) => {
    return api.delete(`/feeds/${feedId}`);
  };

  const { mutate: delete_feed_mutate } = useMutation(removeFeed, {
    onSuccess: () => {
      navigate("/dashboard/feeds");
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });

  const handleFeedDelete = (feedId) => {
    Swal.fire({
      title: "Are you sure ?",
      text: "You want to delete this Feed.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Ok`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        delete_feed_mutate(feedId);
      }
    });
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (row, record) => {
        return (
          <>
            <Avatar
              src={<Image src={row} />}
              size="large"
              onClick={() => console.log("key", record.key)}
            />
            <label
              style={{
                cursor: "pointer",
              }}
              htmlFor="file-input-feed"
            >
              <MdModeEditOutline
                style={{
                  cursor: "pointer",
                  color: "#00ABC5",
                  position: "absolute",
                  bottom: "1rem",
                }}
                size={15}
                onClick={() => setFeedId(record.key)}
              />
            </label>
            <input
              id="file-input-feed"
              type="file"
              className="hidden"
              accept="image/png, image/jpeg"
              onChange={(e) => {
                uploadImg(e.target.files[0]);
              }}
            />
          </>
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (row) => {
        return (
          <div>
            <h3 style={{ fontWeight: "600" }}>{row}</h3>
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (_, row) => {
        return (
          <div>
            {!restriction[userData?.data.role.slug].includes("change_feed") && (
              <Dropdown
                overlay={
                  <Menu mode="horizontal">
                    <Menu.Item
                      key="one"
                      onClick={() => {
                        setIsFeedEditModalVisible(true);
                      }}
                      icon={
                        <FaEdit
                          style={{
                            color: "blue",
                            fontSize: "20px",
                            marginRight: "10px",
                          }}
                        />
                      }
                    >
                      Edit
                    </Menu.Item>

                    <Menu.Item
                      key="two"
                      onClick={() => {
                        handleFeedDelete(row.key);
                      }}
                      icon={
                        <FaTrash
                          style={{
                            color: "red",
                            fontSize: "20px",
                            marginRight: "10px",
                          }}
                        />
                      }
                    >
                      Delete
                    </Menu.Item>
                  </Menu>
                }
              >
                <Space>
                  <IoEllipsisVertical
                    style={{
                      cursor: "pointer",
                      color: "#00ABC5",
                    }}
                    size={20}
                  />
                </Space>
              </Dropdown>
            )}
          </div>
        );
      },
      width: "10rem",
    },
  ];

  feed_data?.data?.docs?.forEach((f) => {
    let temp = {
      key: "",
      title: "",
      description: "",
      image: "",
    };
    temp.key = f._id;

    temp.title = f.title;

    temp.description = f.description;

    temp.image = f?.featureImage?.url ?? feed;

    columnData.push(temp);
  });

  return (
    <>
      <div className="main-container">
        <div className="breadcrumb">
          <span
            className="main-title link-element"
            onClick={() => {
              navigate(-1);
            }}
          >
            Feeds
          </span>
        </div>
        <Row
          style={{
            marginTop: "-30px",
          }}
          className="content-end mb-10"
        >
          <PageSearch
            onChange={(e) => {
              debounce(setRegexQuery(e.target.value), 800);
            }}
            placeholder="Search for Feeds"
          />
          {!restriction[userData?.data.role.slug].includes("change_feed") && (
            <Button
              onClick={() => {
                setAddFeedVisible(true);
              }}
              style={{ borderRadius: "0.5em" }}
              type="primary"
            >
              <MdOutlineAdd size={20} />
            </Button>
          )}
        </Row>

        <div>
          <Table
            columns={columns}
            expandedRowRender={(record) => (
              <p
                style={{ margin: 0 }}
                dangerouslySetInnerHTML={{ __html: record.description }}
              ></p>
            )}
            dataSource={columnData}
          />
        </div>
      </div>
      {addFeedVisible && (
        <FeedForm
          isModalVisible={addFeedVisible}
          form={feedForm}
          okText="create"
          handleCancel={handleFeedAddCancel}
          handleOk={handleFeedAddOk}
          loading={feed_add_loading}
          headingText=""
        />
      )}

      {isFeedEditModalVisible && (
        <FeedForm
          isModalVisible={isFeedEditModalVisible}
          form={feedForm}
          okText="Update"
          handleCancel={handleFeedEditCancel}
          handleOk={handleFeedEditOk}
          loading={update_feed_loading}
          headingText="Update this feed"
          initialValues={feed_data?.data}
        />
      )}
    </>
  );
};

export default Feed;
