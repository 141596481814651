import React, { useState } from "react";
import { Row, Col, Form, Menu, Dropdown, Space } from "antd";
import { IoEllipsisVertical } from "react-icons/io5";
import {
  MdOutlineInventory,
  MdOutlineCreate,
  MdModeEditOutline,
} from "react-icons/md";
import { GrGallery } from "react-icons/gr";
import profile from "../../../assets/images/default-user.png";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { api } from "../../../services/Api";
import Loading from "../../../components/common/Loading";
import { FaEdit, FaTrash } from "react-icons/fa";
import Swal from "sweetalert2";
import ClientForm from "./ClientForm";
import { fetchUser } from "../../../services/User";
import { restriction } from "../../../constant/Restrictions";
import {
  MdOutlineDriveFileRenameOutline,
  MdEmail,
  MdContactPhone,
  MdOutlineManageAccounts,
  MdOutlineNaturePeople,
  MdOutlineTimer,
  MdOutlinePlace,
} from "react-icons/md";

import { FaPlaceOfWorship } from "react-icons/fa";
import ProfileCard from "../../../components/common/ProfileCard";
import { limit } from "../../../utils/truncateString";

function ClientProfile() {
  const navigate = useNavigate();
  const [clientForm] = Form.useForm();
  const [isClientEditModalVisible, setClientEditModalVisible] = useState(false);
  const { isUserLoading, data: userData } = useQuery("user", fetchUser);

  let { clientId } = useParams();
  const getData = () => {
    return api.get("/users/" + clientId);
  };
  const {
    isLoading,
    data,
    refetch: client_refetch,
  } = useQuery("clientdetail", getData);
  const removeClient = () => {
    return api.delete(`/users/${clientId}`);
  };
  const handleClientEditCancel = () => {
    clientForm.resetFields();
    setClientEditModalVisible(false);
  };
  const handleClientEditOk = () => {
    clientForm
      .validateFields()
      .then((values) => {
        client_patch_mutate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  const { mutate: client_delete_mutate, isLoading: client_delete_loading } =
    useMutation(removeClient, {
      onSuccess: () => {
        navigate("/dashboard/clients");
      },
      onError: () => {
        alert("There was an error");
      },
    });
  const handleClientDelete = () => {
    Swal.fire({
      title: "Are you sure ?",
      text: "You want to delete this Company.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Ok`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        client_delete_mutate();
      }
    });
  };
  const patchClient = (data) => {
    return api.patch(`/users/${clientId}`, data);
  };
  const { mutate: client_patch_mutate, isLoading: client_patch_loading } =
    useMutation(patchClient, {
      onSuccess: () => {
        client_refetch();
        clientForm.resetFields();
        setClientEditModalVisible(false);
      },
      onError: () => {
        alert("There was an error");
      },
    });
  if (isLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Loading />
      </div>
    );
  }
  const menu = (
    <Menu mode="horizontal">
      <Menu.Item
        key="one"
        onClick={() => {
          setClientEditModalVisible(true);
        }}
        icon={
          <FaEdit
            style={{
              color: "blue",
              fontSize: "20px",
              marginRight: "10px",
            }}
          />
        }
      >
        Edit
      </Menu.Item>

      <Menu.Item
        key="two"
        onClick={() => {
          handleClientDelete();
        }}
        icon={
          <FaTrash
            style={{
              color: "red",
              fontSize: "20px",
              marginRight: "10px",
            }}
          />
        }
      >
        Delete
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <div className="main-container">
        <Row className="content-apart">
          <div className="breadcrumb">
            <span
              onClick={() => {
                navigate(-1);
              }}
              className="main-title link-element"
            >
              Clients/
            </span>
            <span className="main-subtitle">{data?.data.userName}</span>
          </div>

          {!restriction[userData?.data.role.slug].includes("change_client") && (
            <Dropdown overlay={menu}>
              <Space>
                <IoEllipsisVertical size={20} />
              </Space>
            </Dropdown>
          )}
        </Row>
        <Row>
          <Col span={12}>
            <ProfileCard
              imgSrc={data?.data.avatar?.url ?? profile}
              icon={<MdModeEditOutline />}
              title={`${data?.data?.firstName} ${data?.data?.lastName}`}
            >
              <Row className="content-center profile-meta">
                <MdContactPhone className="mr-10" />
                {data?.data.phoneNumber}
              </Row>
              <Row className="content-center profile-meta">
                <p>
                  <MdEmail /> {limit(data?.data.email, 14)}
                </p>
              </Row>
            </ProfileCard>
          </Col>
          <Col span={12}>
            <div className="client-company-details shadow-card">
              <h1 style={{ fontSize: "24px", fontWeight: "bold" }}>
                Company Details
              </h1>
              <hr />
              <p style={{ fontSize: "15px", opacity: "0.8" }}>
                <MdOutlineDriveFileRenameOutline /> &nbsp; <span>Name: </span>
                {data?.data.company?.name}
              </p>
              <p style={{ fontSize: "15px", opacity: "0.8" }}>
                <MdContactPhone /> &nbsp; <span>Contact: </span>
                {data?.data.company?.phone}
              </p>
              <p style={{ fontSize: "15px", opacity: "0.8" }}>
                <MdOutlineTimer /> &nbsp; <span>NepaliName: </span>
                {data?.data.company?.nepaliName}
              </p>
              <p style={{ fontSize: "15px", opacity: "0.8" }}>
                <FaPlaceOfWorship /> &nbsp; <span>District: </span>
                {data?.data.company?.address.district}
              </p>
            </div>
          </Col>
        </Row>
      </div>

      {isClientEditModalVisible && (
        <ClientForm
          isModalVisible={isClientEditModalVisible}
          form={clientForm}
          okText="Update"
          handleCancel={handleClientEditCancel}
          handleOk={handleClientEditOk}
          loading={client_patch_loading}
          headingText="Update this client"
          initialValues={data?.data}
        />
      )}
    </>
  );
}

export default ClientProfile;
