import React, { useEffect, useState } from "react";

function CountingCard({ number, children, icon, cardColor = "#4faab9" }) {
  const [stateNumber, setStateNumber] = useState(0);
  useEffect(() => {
    for (let i = 0; i <= number; i++) {
      setTimeout(() => setStateNumber(i), 200);
    }
  }, [number]);

  return (
    <div className="details-card" style={{ border: "1px solid #e6d9d9" }}>
      <div className="detail-icon">{icon}</div>
      <span className="total-info" style={{ color: cardColor }}>
        {stateNumber}
      </span>
      {children}
    </div>
  );
}

export default CountingCard;
