import React from "react";
import { Row, Col } from "antd";
import LoginForm from "../components/login/LoginForm";
import SocialLogin from "../components/login/SocialLogin";
import logInImg from "../assets/images/loginimg.webp";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function Login() {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/dashboard");
      window.location.reload(); // force a refresh w/ the server
    }
  }, []);

  return (
    <div id="login">
      <Row>
        <Col
          lg={16}
          sm={24}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={logInImg} alt="loginImg" style={{ borderRadius: "4em" }} />
        </Col>
        <Col
          lg={8}
          sm={24}
          className="login-content"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div className="login-heading">Login With</div>
            <SocialLogin />
            <div className="or">-OR-</div>
            <LoginForm />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
