import React, { useEffect, useState } from "react";
import { Avatar, Button, Layout, Menu, Modal } from "antd";
import { useMutation, useQuery } from "react-query";
import { Routes, Route, useNavigate } from "react-router-dom";
import Main from "./dashboard/Main";
import Clients from "./dashboard/client/Clients";
import Tasks from "./dashboard/task/Task";
import TaskDetail from "./dashboard/task/TaskDetail";
import Employees from "../pages/dashboard/employee/Employee";
import EmployeeProfile from "../pages/dashboard/employee/EmployeeProfile";
import Histories from "./dashboard/History";
import Notifications from "./dashboard/Notification";
import News from "./dashboard/News";
import ProductCategory from "./dashboard/product/ProductCategory";
import Products from "./dashboard/product/Products";
import defaultUser from "../assets/images/default-user.png";
import verified from "../assets/icons/verified.svg";
import { FiLogOut } from "react-icons/fi";
import { FaTasks } from "react-icons/fa";
import { BsBuilding, BsKey } from "react-icons/bs";
import { HiMenuAlt3 } from "react-icons/hi";
import {
  MdOutlineProductionQuantityLimits,
  MdDashboard,
  MdPeopleOutline,
  MdOutlineGroups,
  MdOutlineNotifications,
  MdChat,
} from "react-icons/md";
import ClientProfile from "./dashboard/client/ClientProfile";
import BillTypes from "./dashboard/bill/types";
import BillDetail from "./dashboard/bill/detail";
import Loading from "../components/common/Loading";
import ProductBrand from "./dashboard/product/ProductBrands";
import ProductUOM from "./dashboard/product/ProductUOM";
import Companies from "./dashboard/company/Companies";
import CompanyProfile from "./dashboard/company/CompanyDetail";
import { fetchUser } from "../services/User";
import Merchants from "./dashboard/merchant/Merchants";
import InventoryHome from "./dashboard/inventory/InventoryHome";
import Feed from "./dashboard/feed/Feed";
import FeedDetail from "./dashboard/feed/FeedDetail";
import { restriction } from "../constant/Restrictions";
import InventoryPage from "./dashboard/inventory/Inventory";
import { useForm } from "react-hook-form";
import { api } from "../services/Api";
import hidePwdImg from "../components/login/hide-password.svg";
import showPwdImg from "../components/login/show-password.svg";

const { Sider, Content } = Layout;
function Dashboard() {
  const [loadingBtn, setLoadingBtn] = useState(false);

  const [passwordShown, setPasswordShown] = useState({
    old: false,
    new: false,
    confirm: false,
  });

  // change password api
  const ChangePw = (data) => {
    setLoadingBtn(true);
    const payload = {
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
    };
    return api.patch("users/change-password", payload);
  };
  const { mutate: changePwMutate } = useMutation(ChangePw, {
    onSuccess: () => {
      setIsModalVisible(false);
      setLoadingBtn(false);
      handleLogout();
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
      setLoadingBtn(false);
    },
  });

  const navigate = useNavigate();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log("pw", data);
    changePwMutate(data);
    reset();
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token === null) {
      navigate(-1);
    }
  }, [navigate]);

  let items = [
    {
      key: "/dashboard",
      icon: <MdDashboard />,
      label: "Dashboard",
    },
    {
      key: "/dashboard/tasks",
      icon: <FaTasks size={20} />,
      label: "Tasks",
    },

    {
      key: "/dashboard/notifications",
      icon: <MdOutlineNotifications size={20} />,
      label: "Notifications",
    },

    {
      key: "/dashboard/companies",
      icon: <BsBuilding size={20} />,
      label: "Companies",
    },
    {
      key: "/dashboard/feeds",
      icon: <MdChat size={20} />,
      label: "Feeds",
    },
  ];

  const adminItems = [
    {
      key: "/dashboard/employees",
      icon: <MdPeopleOutline size={20} />,
      label: "Employee",
    },
    {
      key: "/dashboard/clients",
      icon: <MdOutlineGroups size={20} />,
      label: "Clients",
    },
    {
      key: "/dashboard/inventory/home",
      icon: <MdOutlineProductionQuantityLimits size={20} />,
      label: "Inventory",
    },
  ];
  const { isLoading, data: userData } = useQuery("user", fetchUser);
  const [collapsed, setCollapssed] = useState(true);

  if (isLoading) {
    return (
      <div className="loader-center">
        <Loading />
      </div>
    );
  }

  if (!restriction[userData?.data.role.slug]?.includes("view_inventory")) {
    items = [...items, ...adminItems];
  }

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const handleChanagePw = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    clearErrors();
    reset();
    setIsModalVisible(false);
    setLoadingBtn(false);
  };

  return (
    <Layout>
      <Sider
        collapsedWidth={60}
        trigger={null}
        collapsed={collapsed}
        collapsible
        width={220}
        className="sidebar"
      >
        <div>
          <div className="hammenu">
            <HiMenuAlt3
              size={20}
              onClick={() => {
                setCollapssed(!collapsed);
              }}
            />
          </div>
          {userData?.data.avatar?.url ? (
            <Avatar size="large" src={userData.data.avatar.url} alt="" />
          ) : (
            <Avatar size="large" src={defaultUser} alt="" />
          )}
          {!collapsed && (
            <>
              <div className="name">
                <span className="first" style={{ paddingRight: "6px" }}>
                  {`${userData?.data.firstName} ${userData?.data.lastName}`}
                </span>
                <span className="verified">
                  <img src={verified} alt=""></img>
                </span>
              </div>
              <div className="position">{userData?.data?.role?.name ?? ""}</div>
            </>
          )}
          <Menu
            className="side-nav"
            onClick={(e) => {
              if (e.key === "/dashboard/inventory/home") {
                window.open(e.key, "_blank");
              } else {
                navigate(e.key);
              }
            }}
            items={items}
          />
        </div>
        <div>
          <button className="btn-logout" onClick={handleChanagePw}>
            {collapsed ? <BsKey size={20} /> : "Change password"}
          </button>
          <button className="btn-logout" onClick={handleLogout}>
            {collapsed ? <FiLogOut size={20} /> : "Log out"}
          </button>
        </div>
      </Sider>
      <Content className="main-content">
        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route exact path="/clients" element={<Clients />} />
          <Route path="/clients/:clientId" element={<ClientProfile />} />
          <Route
            path="/clients/:clientId/billtypes/company/:companyId"
            element={<BillTypes />}
          />
          <Route
            path="/clients/:clientId/billtypes/company/:companyId/:billId"
            element={<BillDetail />}
          />
          <Route
            path="/companies/:companyId/bill-items"
            element={<BillTypes />}
          />
          <Route
            path="/companies/:companyId/bill-items/:billId"
            element={<BillDetail />}
          />

          <Route path="/tasks" element={<Tasks />} />
          <Route path="/tasks/:taskId" element={<TaskDetail />} />
          <Route path="/employees" element={<Employees />} />
          <Route path="/employees/:employeeId" element={<EmployeeProfile />} />
          <Route path="/productCategory" element={<ProductCategory />} />
          <Route path="/brands" element={<ProductBrand />} />
          <Route path="/uom" element={<ProductUOM />} />
          <Route path="/companies" element={<Companies />} />
          <Route path="/companies/:companyId" element={<CompanyProfile />} />
          <Route
            path="/companies/:companyId/inventory"
            element={<InventoryPage />}
          />
          <Route path="/inventory/home" element={<InventoryHome />} />
          <Route path="/feeds" element={<Feed />} />
          <Route path="/feeds/:feedId" element={<FeedDetail />} />
          <Route path="/products" element={<Products />} />
          <Route path="/merchants" element={<Merchants />} />
          <Route path="/histories" element={<Histories />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/news" element={<News />} />
        </Routes>
      </Content>
      <Modal
        onCancel={handleCancel}
        footer={false}
        maskClosable={false}
        title={"Change Password"}
        visible={isModalVisible}
        style={{
          overflow: "hidden",
          background: "white",
          paddingBottom: "0px",
          borderRadius: "1rem",
        }}
      >
        {/* <ChangePw /> */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            style={{
              position: "relative",
              border: "1px solid #c4bfbf",
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              padding: "0 0.5rem",
            }}
          >
            <input
              type={passwordShown.old ? "text" : "password"}
              className="st-input"
              style={{ marginBottom: "0", border: "none", outline: "none" }}
              name="oldPassword"
              placeholder="Enter Old Password"
              {...register("oldPassword", {
                required: "This field is required",
                minLength: {
                  value: 6,
                  message: "Minimum 6 character is required",
                },
              })}
            />

            <img
              style={{ cursor: "pointer" }}
              width={20}
              height={20}
              src={passwordShown.old ? hidePwdImg : showPwdImg}
              onClick={() =>
                setPasswordShown({ ...passwordShown, old: !passwordShown.old })
              }
              alt="password"
              className="passEye"
            />
          </div>
          <span
            style={{
              color: "red",
              marginTop: "-0.5rem",
              display: "block",
              marginBottom: "1rem",
            }}
          >
            {errors?.oldPassword?.message}
          </span>

          <div
            style={{
              position: "relative",
              border: "1px solid #c4bfbf",
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              padding: "0 0.5rem",
            }}
          >
            <input
              style={{ marginBottom: "0", border: "none", outline: "none" }}
              type={passwordShown.new ? "text" : "password"}
              className="st-input"
              name="newPassword"
              placeholder="Enter New Password"
              {...register("newPassword", {
                required: "This field is required",
                minLength: {
                  value: 6,
                  message: "Minimum 6 character is required",
                },
              })}
            />
            <img
              style={{ cursor: "pointer" }}
              width={20}
              height={20}
              src={passwordShown.new ? hidePwdImg : showPwdImg}
              onClick={() =>
                setPasswordShown({ ...passwordShown, new: !passwordShown.new })
              }
              alt="password"
              className="passEye"
            />
          </div>
          <span
            style={{
              color: "red",
              marginTop: "-0.5rem",
              display: "block",
              marginBottom: "1rem",
            }}
          >
            {errors.newPassword?.message}
          </span>
          <div
            style={{
              position: "relative",
              border: "1px solid #c4bfbf",
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              padding: "0 0.5rem",
            }}
          >
            <input
              style={{ marginBottom: "0", border: "none", outline: "none" }}
              type={passwordShown.confirm ? "text" : "password"}
              className="st-input"
              name="confirmPassword"
              placeholder="Confirm New Password"
              {...register("confirmPassword", {
                required: "This field is required",
                minLength: {
                  value: 6,
                  message: "Minimum 6 character is required",
                },
                validate: (value) => {
                  console.log(value, watch("newPassword"));
                  if (watch("newPassword") !== value) {
                    console.log("error", errors);
                    return "Your password does not match";
                  }
                },
              })}
            />

            <img
              style={{ cursor: "pointer" }}
              width={20}
              height={20}
              src={passwordShown.confirm ? hidePwdImg : showPwdImg}
              onClick={() =>
                setPasswordShown({
                  ...passwordShown,
                  confirm: !passwordShown.confirm,
                })
              }
              alt="password"
              className="passEye"
            />
          </div>
          <span
            style={{
              color: "red",
              marginTop: "-0.5rem",
              display: "block",
              marginBottom: "1rem",
            }}
          >
            {errors.confirmPassword?.message}
          </span>
          <div>
            <button className="btn-custom mt-16" loading={loadingBtn}>
              {loadingBtn ? <Loading isSmall /> : "Change Password"}
            </button>
          </div>
        </form>
      </Modal>
    </Layout>
  );
}

export default Dashboard;
