import React from "react";
import { api } from "../../services/Api";
import { useQuery } from "react-query";
import moment from "moment";
import { restriction } from "../../constant/Restrictions";
import { fetchUser } from "../../services/User";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function BarChart({ data }) {
  const options = {
    responsive: true,

    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Monthly Task Progress Report",
      },
    },
  };

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const data2 = {
    labels,
    datasets: [
      {
        label: "Pending Task",
        // data: labels?.map((_, index) => data?.find()),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Ongoing Task",
        data: [5, 15, 25, 35, 45, 55, 65, 80],
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Completed Task",
        data: [8, 12, 28, 30, 42, 50, 68, 75],
        backgroundColor: "rgba(53, 235, 99, 0.5)",
      },
    ],
  };
  return (
    <>
      <Bar height={175} options={options} data={data2} />
    </>
  );
}

export default BarChart;
