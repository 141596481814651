export const restriction = {
  admin: [],
  applicationadministrator: [],
  superadmin: [],
  moderator: [
    "change_employee",
    "change_client",
    "change_feed",
    "change_notification",
    "change_inventory",
    "access_modify",
  ],
  agent: [
    "view_inventory",
    "change_notification",
    "change_company",
    "access_modify",
  ],
  user: ["view_inventory", "view_company", "change_user"],
  employee: [
    "change_employee",
    "change_client",
    "change_task",
    "change_feed",
    "change_notification",
    "change_company",
    "change_inventory",
    "access_modify",
    "view_inventory",
    "view_all_task",
    "view_all_client",
    "view_all_company",
    "view_all_notification",
    "add_attachment",
    "add_assignee",
  ],
};
