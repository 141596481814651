import React, { useState } from "react";
import { Button, Modal, Form, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useRef, useMemo } from "react";
import JoditEditor from "jodit-react";

function FeedForm({
  isModalVisible,
  handleCancel,
  handleOk,
  form,
  loading,
  initialValues,
  okText,
  headingText,
}) {

  const editor = useRef(null);
  const [content, setContent] = useState(null);

  const config = {
    border:"2px solid red",
    height:"100%",
    readonly: false,
    placeholder: "Start typings...",
  };

  return (
    <Modal
      maskClosable={false}
      // title={headingText}
      visible={isModalVisible}
      onCancel={handleCancel}
      okText="Create"
      cancelText="Cancel"
      width={"30vw"}
      style={{ background: "white", top: "0px" }}
      className="global-modal"
      footer={[
        <Button
          loading={loading}
          htmlType="submit"
          type="primary"
          onClick={handleOk}
        >
          {okText}
        </Button>,
      ]}
    >
      <div className="form-wrapper">
        <Form initialValues={initialValues} form={form} layout="vertical">
          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                message: "Please input title!",
              },
            ]}
          >
            <Input placeholder="title" />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                required: true,
                message: "Please input description!",
              },
            ]}
          >
            {/* <TextArea rows={4} placeholder="Description" /> */}
            {/* {console.log(convertToRaw(editorState.getCurrentContent()))}
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={(data) => setEditorState(data)}
            /> */}

            <JoditEditor
              ref={editor}
              // config={config}
              value={content}
              onChange={(newContent) => setContent(newContent)}
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}

export default FeedForm;
