import React, { useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { IoEllipsisVertical } from "react-icons/io5";
import { BiUserPlus } from "react-icons/bi";
import { BsFlag } from "react-icons/bs";
import Profile from "../../../assets/images/default-user.png";
import {
  Row,
  Form,
  Col,
  Menu,
  Card,
  Badge,
  Tag,
  Tabs,
  Table,
  Avatar,
  Dropdown,
  Space,
  Tooltip,
  Popover,
  Button,
  Modal,
  Input,
  message,
} from "antd";
import { useQuery, useMutation } from "react-query";
import { useParams, Link, useNavigate } from "react-router-dom";
import { api } from "../../../services/Api";
import { restriction } from "../../../constant/Restrictions";
import Loading from "../../../components/common/Loading";
import Swal from "sweetalert2";
import EmployeeEditForm from "./EmployeeEditForm";
import { fetchUser } from "../../../services/User";
import moment from "moment";
import {
  MdOutlineDriveFileRenameOutline,
  MdEmail,
  MdContactPhone,
  MdOutlineManageAccounts,
  MdModeEditOutline,
} from "react-icons/md";
import { BsPersonCircle } from "react-icons/bs";
import { FaUserEdit } from "react-icons/fa";
import defaultCompany from "../../../assets/images/default-company.png";
import ProfileCard from "../../../components/common/ProfileCard";
import AssignCard from "../../../components/common/AssignCard";
import { limit } from "../../../utils/truncateString";
import { stringToColour } from "../../../utils/getProfileColor";
import DebounceSelect from "../../../components/common/DebounceSelect";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

function EmployeeProfile() {
  let { employeeId } = useParams();
  const navigate = useNavigate();
  const [employeeForm] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { isUserLoading, data: userData } = useQuery("user", fetchUser);
  const [isEmployeeEditModalVisible, setEmployeeEditModalVisible] =
    useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handlePasswordOk = () => {
    setIsModalOpen(false);
  };
  const handlePasswordCancel = () => {
    setIsModalOpen(false);
  };
  //table part
  const { TabPane } = Tabs;

  const fetchTask = () => {
    return api.get("/tasks", {
      params: {
        assignee: employeeId,
      },
    });
  };

  const {
    // isLoading: task_loading,
    data: task_data,
    // refetch: task_refetch,
  } = useQuery("task", fetchTask);

  //ongoing task
  const taskOngoing_data = task_data?.data?.docs.filter(
    (item) => item.status === "Ongoing"
  );
  console.log("task_data", task_data);

  const dueTodayArray = taskOngoing_data?.filter((task) => {
    return (
      moment(task.dueDate).format("YYYY-MM-DD") ===
      moment(Date()).format("YYYY-MM-DD")
    );
  });

  const date = new Date();
  const tommorow = date.setDate(date.getDate() + 1);
  const dueTomorrowArray = taskOngoing_data?.filter((task) => {
    return (
      moment(task.dueDate).format("YYYY-MM-DD") ===
      moment(tommorow).format("YYYY-MM-DD")
    );
  });

  //companies
  const assignEmployee = async (id) => {
    return await api.get(`/companies`, { params: { assignee: employeeId } });
  };

  const getUsers = async (queryString, exclude) => {
    return await api
      .get("/users", {
        params: {
          "userName!": exclude.join(","),
          "relation_role.slug!": "user",
          firstName: encodeURI(`/${queryString}/i`),
        },
      })
      .then((body) =>
        body.data.docs.map((user) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user._id,
        }))
      );
  };

  const {
    isLoading: company_loading,
    data: company_data,
    refetch: company_refetch,
  } = useQuery("company", assignEmployee);

  const colorByStatus = {
    Pending: "volcano",
    Ongoing: "yellow",
    Completed: "green",
  };

  const priorityFlag = {
    Low: "green",
    High: "red",
    Medium: "#e4d422",
  };
  const [popoverVisible, setPopoverVisible] = useState(false);
  const assignTask = (data) => {
    const payload = {
      assignee: data.assignee,
    };
    return api.patch("/tasks/" + data.taskId + "/assign-user", payload);
  };
  const { mutate: assign_task_mutate } = useMutation(assignTask, {
    onSuccess: () => {
      refetch();
      setPopoverVisible(false);
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });

  const priorityTask = (data) => {
    const payload = {
      priority: data.priority,
    };
    return api.patch("/tasks/" + data.taskId, payload);
  };
  const { mutate: priority_task_mutate } = useMutation(priorityTask, {
    onSuccess: () => {
      refetch();
      setPopoverVisible(false);
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });

  //task count api
  const count_Task = task_data?.data?.docs.length;

  const count_OnTask = taskOngoing_data?.length;

  //completed task
  const completed_Task = task_data?.data?.docs.filter(
    (item) => item.status === "Completed"
  );

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record) => (
        <p style={{ color: "black" }}>{limit(text, 40)}</p>
      ),
      width: "40%",
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      sorter: (a, b) => moment(a.dueDate) - moment(b.dueDate),
      render: (text) => <p>{moment(text).fromNow()}</p>,
    },
    {
      title: "Others",
      dataIndex: "assignee",
      key: "assignee",
      render: (assignee, record) => (
        <Avatar.Group
          maxCount={5}
          maxPopoverTrigger="hover"
          size="small"
          maxStyle={{
            color: "#f56a00",
            backgroundColor: "#fde3cf",
            cursor: "pointer",
          }}
        >
          {assignee.map((user, i) => {
            const color = stringToColour(user.userName);
            return (
              <>
                <div key={i}>
                  <Tooltip
                    title={<div>{`${user.firstName} ${user.lastName}`}</div>}
                    placement="top"
                  >
                    {user.avatar?.url ? (
                      <Avatar src={user.avatar.url} />
                    ) : (
                      <Avatar
                        className="capital"
                        style={{ backgroundColor: color }}
                      >
                        {user.firstName[0] ?? "U"}
                      </Avatar>
                    )}
                  </Tooltip>
                </div>
              </>
            );
          })}
          <Popover
            open={popoverVisible}
            onOpenChange={() => setPopoverVisible(true)}
            content={
              <div
                style={{
                  width: "300px",
                }}
              >
                <DebounceSelect
                  fetchOptions={(query) =>
                    getUsers(query, assignee?.map((e) => e.userName) ?? [])
                  }
                  onChange={(e) => {
                    assign_task_mutate({ taskId: record._id, assignee: e });
                  }}
                  placeholder="Assignee"
                />
              </div>
            }
            trigger="click"
            placement="bottomLeft"
          >
            <Avatar
              className="capital"
              size="small"
              style={{
                backgroundColor: "#87d068",
                cursor: "pointer",
              }}
            >
              <BiUserPlus size={12} />
            </Avatar>
          </Popover>
        </Avatar.Group>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        {
          text: "Pending",
          value: "Pending",
        },
        {
          text: "Ongoing",
          value: "Ongoing",
        },
        {
          text: "Completed",
          value: "Completed",
        },
      ],
      onFilter: (value, record) => record.status === value,
      render: (status) => {
        return <Tag color={colorByStatus[status]}>{status}</Tag>;
      },
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (priority, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                onClick={() =>
                  priority_task_mutate({
                    taskId: record._id,
                    priority: "High",
                  })
                }
              >
                High
              </Menu.Item>
              <Menu.Item
                onClick={() =>
                  priority_task_mutate({
                    taskId: record._id,
                    priority: "Medium",
                  })
                }
              >
                Medium
              </Menu.Item>
              <Menu.Item
                onClick={() =>
                  priority_task_mutate({
                    taskId: record._id,
                    priority: "Low",
                  })
                }
              >
                Low
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <BsFlag color={priorityFlag[priority ?? "Medium"]} />
        </Dropdown>
      ),
    },
  ];
  const [task, setTask] = useState("");

  const handleCancel = () => {
    setVisible(false);
  };

  const getData = () => {
    return api.get("/users/" + employeeId);
  };
  const getNewTask = () => {
    return api.get("/tasks");
  };
  const getCompany = () => {
    return api.get("/companies");
  };
  const getUserTask = () => {
    return api.get("/tasks", { params: { assignee: employeeId } });
  };

  const createTask = (data) => {
    return api.post("/tasks", data, employeeId);
  };
  const updateAssignee = (data) => {
    return api.patch(`/tasks/${task}/assign-user`, data);
  };
  const { mutate: mutate1 } = useMutation(createTask, {
    onSuccess: () => {
      refetch();
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });
  const { mutate: mutate2 } = useMutation(updateAssignee, {
    onSuccess: () => {
      refetch();
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });

  const {
    isLoading,
    data: empdetail,
    refetch: employee_refetch,
  } = useQuery("employeedetail", getData);

  const { data: newtask } = useQuery("newtask", getNewTask);
  const { data: data3 } = useQuery("allcompany", getCompany);
  const { data: usertask, refetch } = useQuery("emptask", getUserTask);

  const assigneeTask = () => {
    mutate2({ assignee: employeeId });
    handleCancel();
  };

  const updateProfilePic = (data) => {
    const payload = {
      avatar: data.avatar,
    };
    const formData = new FormData();
    formData.append("avatar", data.avatar);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return api.patch(
      `/users/${employeeId}/update-profile-pic`,
      formData,
      config,
      payload
    );
  };

  const { mutate: update_profile_mutate, isLoading: update_profile_loading } =
    useMutation(updateProfilePic, {
      onSuccess: () => {
        refetch();
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });

  const uploadImage = (avatar) => {
    update_profile_mutate({ avatar: avatar });
  };

  const patchEmployee = (data) => {
    return api.patch(`/users/${employeeId}`, data);
  };
  const { mutate: employee_patch_mutate, isLoading: employee_patch_loading } =
    useMutation(patchEmployee, {
      onSuccess: () => {
        employee_refetch();
        employeeForm.resetFields();
        setEmployeeEditModalVisible(false);
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });

  const patchPassword = (data) => {
    const payload = {
      password: data.password,
    };
    return api.patch(`/users/${employeeId}/update-password`, payload);
  };

  const { mutate: password_patch_mutate, isLoading: password_patch_loading } =
    useMutation(patchPassword, {
      onSuccess: () => {
        employee_refetch();
        passwordForm.resetFields();
        setIsModalOpen(false);
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });

  const handleEmployeeEditCancel = () => {
    employeeForm.resetFields();
    setEmployeeEditModalVisible(false);
  };
  const handleEmployeeEditOk = () => {
    employeeForm
      .validateFields()
      .then((values) => {
        employee_patch_mutate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  const removeEmployee = () => {
    return api.delete(`/users/${employeeId}`);
  };
  const { mutate: employee_delete_mutate, isLoading: employee_delete_loading } =
    useMutation(removeEmployee, {
      onSuccess: () => {
        navigate("/dashboard/employees");
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });
  const handleEmployeeDelete = () => {
    Swal.fire({
      title: "Are you sure ?",
      text: "You want to delete this Employee.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Ok`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        employee_delete_mutate();
      }
    });
  };
  if (isLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Loading />
      </div>
    );
  }

  const menu = (
    <Menu mode="horizontal">
      <Menu.Item
        key="one"
        onClick={() => {
          setEmployeeEditModalVisible(true);
        }}
        icon={
          <FaEdit
            style={{
              color: "blue",
              fontSize: "20px",
              marginRight: "10px",
            }}
          />
        }
      >
        Edit
      </Menu.Item>

      <Menu.Item
        key="two"
        onClick={() => {
          handleEmployeeDelete();
        }}
        icon={
          <FaTrash
            style={{
              color: "red",
              fontSize: "20px",
              marginRight: "10px",
            }}
          />
        }
      >
        Delete
      </Menu.Item>
      <Menu.Item
        key="three"
        icon={
          <FaUserEdit
            style={{
              color: "green",
              fontSize: "20px",
              marginRight: "8px",
            }}
          />
        }
        onClick={showModal}
      >
        Change Password
      </Menu.Item>
    </Menu>
  );

  const toggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  /////////////table content................
  const client_columns = [
    {
      width: "20%",
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (company, _record) => {
        return (
          <p
            onClick={() => {
              navigate(`/dashboard/companies/${_record._id}`);
            }}
          >
            {company}
          </p>
        );
      },
    },

    {
      width: "20%",
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (company, _record) => (
        <p
          onClick={() => {
            navigate(`/dashboard/companies/${_record._id}`);
          }}
        >
          {limit(company, 30)}
        </p>
      ),
    },
    {
      width: "20%",
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (company, _record) => (
        <>
          <p
            onClick={() => {
              navigate(`/dashboard/companies/${_record._id}`);
            }}
          >
            {limit(company, 30)}
          </p>
        </>
      ),
    },

    {
      width: "20%",
      title: "Pan",
      dataIndex: "pan",
      key: "pan",
      render: (company, _record) => (
        <>
          <p
            onClick={() => {
              navigate(`/dashboard/companies/${_record._id}`);
            }}
          >
            {limit(company, 30)}
          </p>
        </>
      ),
    },

    {
      width: "20%",
      title: "Assigned To",
      dataIndex: "assignee",
      key: "assignee",
      render: (assignee, record) => (
        <Avatar.Group
          maxCount={5}
          maxPopoverTrigger="hover"
          size="small"
          maxStyle={{
            color: "#f56a00",
            backgroundColor: "#fde3cf",
            cursor: "pointer",
          }}
        >
          {assignee.map((user, i) => {
            const color = stringToColour(user.userName);
            return (
              <>
                <div key={i}>
                  <Tooltip
                    title={<div>{`${user.firstName} ${user.lastName}`}</div>}
                    placement="top"
                  >
                    {user.avatar?.url ? (
                      <Avatar src={user.avatar.url} />
                    ) : (
                      <Avatar
                        className="capital"
                        style={{ backgroundColor: color }}
                      >
                        {user.firstName[0] ?? "U"}
                      </Avatar>
                    )}
                  </Tooltip>
                </div>
              </>
            );
          })}
          {!restriction[userData?.data.role.slug]?.includes("add_assignee") && (
            <Popover
              open={popoverVisible}
              onOpenChange={() => setPopoverVisible(true)}
              content={
                <div
                  style={{
                    width: "300px",
                  }}
                >
                  <DebounceSelect
                    fetchOptions={(query) =>
                      getUsers(query, assignee?.map((e) => e.userName) ?? [])
                    }
                    onChange={(e) => {
                      assign_task_mutate({ taskId: record._id, assignee: e });
                    }}
                    placeholder="Assignee"
                  />
                </div>
              }
              trigger="click"
              placement="bottomLeft"
            >
              <Avatar
                className="capital"
                size="small"
                style={{
                  backgroundColor: "#87d068",
                  cursor: "pointer",
                }}
              >
                <BiUserPlus size={12} />
              </Avatar>
            </Popover>
          )}
        </Avatar.Group>
      ),
    },
  ];
  return (
    <div className="main-container">
      <div id="employeeProfile">
        <div className="em-p-box">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="breadcrumb">
              <span
                className="main-title link-element"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Employee /
              </span>
              <span className="main-subtitle">{empdetail.data.userName}</span>
            </div>
            <Row>
              {!restriction[userData?.data.role.slug].includes(
                "change_employee"
              ) && (
                <Dropdown overlay={menu}>
                  <Space>
                    <IoEllipsisVertical
                      style={{
                        cursor: "pointer",
                        color: "#00ABC5",
                      }}
                      size={20}
                    />
                  </Space>
                </Dropdown>
              )}
            </Row>
          </div>
          <Row gutter={16}>
            <Col span={8}>
              <ProfileCard
                imgSrc={empdetail?.data.avatar?.url ?? Profile}
                icon={
                  !restriction[userData?.data.role.slug].includes(
                    "change_employee"
                  ) && (
                    <label
                      style={{
                        cursor: "pointer",
                      }}
                      htmlFor="file-input"
                    >
                      <MdModeEditOutline />
                    </label>
                  )
                }
                title={`${empdetail.data.firstName}  ${empdetail.data.lastName}`}
              >
                <input
                  id="file-input"
                  type="file"
                  className="hidden"
                  accept="image/png, image/jpeg"
                  onChange={(e) => {
                    uploadImage(e.target.files[0]);
                  }}
                />

                <Row className="content-center profile-meta">
                  <BsPersonCircle className="mr-10" />
                  {limit(empdetail.data.role.name, 15)}
                </Row>
                <Row className="content-center profile-meta">
                  <MdContactPhone className="mr-10" />
                  {empdetail.data.phoneNumber}
                </Row>
                <Row className="content-center profile-meta">
                  <p>
                    <MdEmail className="mr-10" />
                    {limit(empdetail.data.email, 15)}
                  </p>
                </Row>
              </ProfileCard>
            </Col>

            <Col span={16}>
              <Row>
                <span
                  style={{
                    marginRight: "20px",
                  }}
                  className="badge-responsive"
                >
                  <Badge color="#bdb5b5" count={count_Task}>
                    <Card
                      hoverable
                      style={{
                        width: "130px",
                        height: "40px",
                        border: "1px solid #bdb5b5",
                        borderRadius: "5px",
                        backgroundColor: "#bdb5b5",
                      }}
                      size="small"
                    >
                      <p
                        style={{
                          fontSize: "13px",
                          textAlign: "center",
                          color: "white",
                          marginTop: "-4px",
                        }}
                      >
                        Assigned Task
                      </p>
                    </Card>
                  </Badge>
                </span>

                <span
                  style={{
                    marginRight: "20px",
                  }}
                >
                  <Badge color="#f8b63c" count={count_OnTask}>
                    <Card
                      hoverable
                      style={{
                        width: "140px",
                        height: "40px",
                        border: "1px solid #f8b63c",
                        borderRadius: "5px",
                        backgroundColor: "#f8b63c",
                      }}
                      size="small"
                    >
                      <p
                        style={{
                          fontSize: "13px",
                          textAlign: "center",
                          color: "white",
                          marginTop: "-4px",
                        }}
                      >
                        Task Ongoing
                      </p>
                    </Card>
                  </Badge>
                </span>

                <span
                  style={{
                    marginRight: "20px",
                  }}
                  className="badge-responsive"
                >
                  <Badge color="#3cad3c" count={completed_Task?.length}>
                    <Card
                      hoverable
                      style={{
                        width: "140px",
                        height: "40px",
                        border: "1px solid #3cad3c",
                        borderRadius: "5px",
                        backgroundColor: "#3cad3c",
                      }}
                      size="small"
                    >
                      <p
                        style={{
                          fontSize: "13px",
                          textAlign: "center",
                          color: "white",
                          marginTop: "-4px",
                        }}
                      >
                        Completed
                      </p>
                    </Card>
                  </Badge>
                </span>

                <span
                  style={{
                    marginRight: "20px",
                  }}
                >
                  <Badge color="#74c6e1" count={dueTodayArray?.length}>
                    <Card
                      hoverable
                      style={{
                        width: "140px",
                        height: "40px",
                        border: "1px solid #74c6e1",
                        borderRadius: "5px",
                        backgroundColor: "#74c6e1",
                      }}
                      size="small"
                    >
                      <p
                        style={{
                          fontSize: "13px",
                          textAlign: "center",
                          color: "white",
                          marginTop: "-4px",
                        }}
                      >
                        Due Today
                      </p>
                    </Card>
                  </Badge>
                </span>

                <span
                  style={{
                    marginRight: "20px",
                  }}
                >
                  <Badge count={dueTomorrowArray?.length}>
                    <Card
                      hoverable
                      style={{
                        width: "140px",
                        height: "40px",
                        border: "1px solid #FF4D4F",
                        borderRadius: "5px",
                        backgroundColor: "#FF4D4F",
                      }}
                      size="small"
                      className="badge-responsive"
                    >
                      <p
                        style={{
                          fontSize: "13px",
                          textAlign: "center",
                          color: "white",
                          marginTop: "-4px",
                        }}
                      >
                        Due Tomorrow
                      </p>
                    </Card>
                  </Badge>
                </span>
              </Row>
            </Col>
          </Row>
        </div>

        <div
          style={{
            marginTop: "10px",
            marginLeft: "13px",
          }}
        >
          {count_OnTask > 0 && (
            <Tag color="green" className="task-tag">
              <h1
                style={{
                  color: "green",
                  textDecoration: "underline",
                }}
              >
                Ongoing Task
              </h1>
            </Tag>
          )}
        </div>

        <div className="emp-detail-card-container">
          {taskOngoing_data?.map((ot, i) => {
            return (
              <>
                <div
                  className="details-card"
                  style={{
                    border: "1px solid #e6d9d9",
                    marginTop: "18px",
                    borderRadius: "25px",
                    marginLeft: "10px",
                  }}
                  key={i}
                >
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      textAlign: "start",
                    }}
                  >
                    {ot.title}
                  </p>
                  <hr />
                  <p
                    style={{
                      fontSize: "14px",
                      opacity: 0.8,
                    }}
                  >
                    Due date: {moment(ot.dueDate).format("MMM Do, h:mm a")}
                  </p>
                  <p>
                    <Button type="primary">{ot.status}</Button>
                  </p>
                </div>
              </>
            );
          })}
        </div>

        <div
          className="basics-info-table"
          style={{
            marginLeft: "10px",
          }}
        >
          <Tabs defaultActiveKey="1">
            <TabPane tab="Info" key="1">
              <div className="emp-details-card">
                <h1 className="dpage-title">Employee Detail</h1>
                <hr />
                <div style={{ marginTop: "15px" }}>
                  <p>
                    <MdOutlineDriveFileRenameOutline />
                    &nbsp; Name: {empdetail.data.firstName}
                    {empdetail.data.lastName}
                  </p>
                  <p>
                    <MdEmail />
                    &nbsp; Email: {empdetail.data.email}
                  </p>
                  <p>
                    <MdContactPhone />
                    &nbsp; Contact: {empdetail.data.phoneNumber}
                  </p>
                  <p>
                    <MdOutlineManageAccounts />
                    &nbsp; Provider: {empdetail.data.provider}
                  </p>
                </div>
              </div>
            </TabPane>

            <TabPane tab="Tasks" key="2">
              <Table
                size="small"
                scroll={{ y: "50vh" }}
                onRow={(record, rowIndex) => {
                  return {
                    // onClick: (event) => {
                    //   navigate(`${record._id}`);
                    // },
                  };
                }}
                className="fiscal-table"
                columns={columns}
                dataSource={task_data?.data.docs}
              />
            </TabPane>

            <TabPane tab="Assigned Clients" key="3">
              <div
                className="site-card-wrapper"
                style={{ marginBottom: "15px" }}
              >
                <Row>
                  <Table
                    columns={client_columns}
                    style={{ width: "100%", overflowX: "scroll" }}
                    dataSource={company_data?.data.docs}
                  />
                </Row>
              </div>
            </TabPane>
          </Tabs>
        </div>

        {isEmployeeEditModalVisible && (
          <EmployeeEditForm
            isModalVisible={isEmployeeEditModalVisible}
            form={employeeForm}
            okText="Update"
            handleCancel={handleEmployeeEditCancel}
            handleOk={handleEmployeeEditOk}
            loading={employee_patch_loading}
            headingText="Update this employee"
            initialValues={empdetail?.data}
          />
        )}
        {isModalOpen && (
          <Modal
            title="Change Password"
            visible={isModalOpen}
            onOk={handlePasswordOk}
            onCancel={handlePasswordCancel}
            footer={[
              <Button
                loading={password_patch_loading}
                type="primary"
                htmlType="submit"
                onClick={() => {
                  passwordForm
                    .validateFields()
                    .then((values) => {
                      password_patch_mutate(values);
                      message.success(
                        <div>
                          <p style={{ color: "black" }}>Password Changed!</p>
                        </div>
                      );
                    })
                    .catch((info) => {
                      console.log("Validate Failed:", info);
                    });
                }}
              >
                Change Password
              </Button>,
            ]}
          >
            <Form form={passwordForm} autoComplete="off" layout="vertical">
              <Form.Item
                label="New Password"
                name="password"
                required
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(
                      // eslint-disable-next-line no-useless-escape
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/
                    ),
                    message:
                      "Password length must be 6 to 10 characters with at least one uppercase, one lowercase, one number and one special character!",
                  },
                ]}
                hasFeedback
              >
                <div style={{ display: "flex" }}>
                  <Input
                    placeholder="New Password"
                    type={showNewPassword ? "text" : "password"}
                  />
                  <Button
                    style={{
                      borderRadius: 8,
                      marginLeft: 10,
                      padding: 5,
                    }}
                    icon={
                      !showNewPassword ? (
                        <AiOutlineEye />
                      ) : (
                        <AiOutlineEyeInvisible />
                      )
                    }
                    onClick={toggleShowNewPassword}
                  ></Button>
                </div>
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name="confirm password"
                required
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The password that you have entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <div style={{ display: "flex" }}>
                  <Input
                    placeholder="Confirm Password"
                    type={showConfirmPassword ? "text" : "password"}
                  />
                  <Button
                    style={{
                      borderRadius: 8,
                      marginLeft: 10,
                      padding: 5,
                    }}
                    icon={
                      !showConfirmPassword ? (
                        <AiOutlineEye />
                      ) : (
                        <AiOutlineEyeInvisible />
                      )
                    }
                    onClick={toggleShowConfirmPassword}
                  ></Button>
                </div>
              </Form.Item>
            </Form>
          </Modal>
        )}
      </div>
    </div>
  );
}

export default EmployeeProfile;
