import React, { useState } from "react";
import { Button, Modal, Form, Input, Switch, Select } from "antd";
import DebounceSelect from "../../../components/common/DebounceSelect";
import { FaInfoCircle } from "react-icons/fa";
import { api } from "../../../services/Api";
import { Municipality } from "../../../constant/Municipalities";
import { Districts } from "../../../constant/Districts";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 20,
    },
    sm: {
      span: 14,
    },
  },
};

const { Option } = Select;

function EmployeeEditForm({
  isModalVisible,
  handleCancel,
  handleOk,
  form,
  loading,
  initialValues,
  okText,
  headingText,
}) {
  const getRoles = async (queryString) => {
    return api
      .get("/roles", {
        params: {
          "slug!": "user,applicationadministrator",
          name: encodeURI(`/${queryString}/i`),
        },
      })
      .then((body) =>
        body.data.docs.map((role) => ({
          label: `${role.name}`,
          value: role._id,
        }))
      );
  };

 
  const [districtId, setId] = useState(1);

  return (
    <Modal
      maskClosable={false}
      // title={headingText}
      visible={isModalVisible}
      onCancel={handleCancel}
      okText="Create"
      cancelText="Cancel"
      width={"30vw"}
      style={{ background: "white", top: "0px" }}
      className="global-modal"
      footer={[
        <Button
          loading={loading}
          key="submit"
          type="primary"
          onClick={handleOk}
        >
          {okText}
        </Button>,
      ]}
    >
      <div className="form-wrapper">
        <Form
          initialValues={{
            ...initialValues,
            address: {
              country: "Nepal",
              district: initialValues?.address?.district
                ? {
                    ...initialValues?.address?.district,
                    label: `${initialValues?.address?.district}`,
                    value: `${initialValues?.address?.district?.districtId}`,
                  }
                : undefined,

              municipality: initialValues?.address?.municipality
                ? {
                    ...initialValues?.address?.municipality,
                    label: `${initialValues?.address?.municipality}`,
                    value: `${initialValues?.address?.municipality?.district_Id}`,
                  }
                : undefined,
            },
            role: initialValues?.role
              ? {
                  ...initialValues.role,
                  label: `${initialValues?.role?.name}`,
                  value: `${initialValues?.role?._id}`,
                }
              : undefined,
          }}
          form={form}
          layout="vertical"
        >
          <Form.Item
            label="Employee First Name"
            name="firstName"
            required
            rules={[
              {
                required: true,
                message: "Please Enter First Name!",
              },
            ]}
          >
            <Input placeholder="First Name" />
          </Form.Item>
          <Form.Item
            label="Employee Last Name"
            name="lastName"
            required
            rules={[
              {
                required: true,
                message: "Please Last Name!",
              },
            ]}
          >
            <Input placeholder="Last Name" />
          </Form.Item>
          <Form.Item
            label="Employee User Name"
            name="userName"
            required
            rules={[
              {
                required: true,
                message: "Please Enter User Name!",
              },
            ]}
          >
            <Input placeholder="User Name" />
          </Form.Item>
          <Form.Item
            label="Employee Email"
            name="email"
            required
            rules={[
              {
                required: true,
                message: "Please enter employee's email!",
              },
              {
                type: "email",
                message: "Please enter a valid email address !",
              },
            ]}
          >
            <Input type="email" placeholder="Employee's Email" />
          </Form.Item>
          <Form.Item
            label="Employee Phone Number"
            name="phoneNumber"
            required
            rules={[
              {
                required: true,
                message: "Please enter employee's phone!",
              },
            ]}
          >
            <Input type="tel" placeholder="Employee's Phone Number" />
          </Form.Item>
          <Form.Item
            label="Employee Role"
            name="role"
            required
            rules={[
              {
                required: true,
                message: "Please role of this employee !",
              },
            ]}
          >
            <DebounceSelect placeholder="Select Role" fetchOptions={getRoles} />
          </Form.Item>
          <Form.Item
            label="Employee Country"
            name={["address", "country"]}
            required
            rules={[
              {
                required: true,
                message: "Please enter employee's country",
              },
            ]}
          >
            <Input defaultValue={"Nepal"} placeholder="Employee's Country" />
          </Form.Item>
          <Form.Item label="Employee District" name={["address", "district"]}>
            <Select value={districtId} onChange={(e) => setId(e)}>
              {Districts?.map((d, i) => {
                return (
                  <Option key={d.id} value={d.id}>
                    {d.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Employee Municipality"
            name={["address", "municipality"]}
          >
            <Select>
              {Municipality?.filter((el) => el.district_id === districtId)?.map(
                (m, i) => {
                  return (
                    <Option key={i} value={m.value}>
                      {m.label}
                    </Option>
                  );
                }
              )}
            </Select>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}

export default EmployeeEditForm;
