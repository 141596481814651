import React from "react";
import { Card, Row, Col } from "antd";
const { Meta } = Card;

function News() {
  return (
    <>
      <div className="dpage-box">
        <h1 className="dpage-title">News Section</h1>
        <div style={{ display: "flex", marginTop: "2rem" }}>
          <Row gutter={30}>
            <Col span="8">
              <Card
                hoverable
                style={{
                  width: 350,
                }}
                cover={
                  <img
                    alt="example"
                    height="300px"
                    src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
                  />
                }
              >
                <Meta
                  title="Europe Street beat"
                  description="www.instagram.com"
                />
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default News;
