import { Button, Modal } from "antd";
import React from "react";

function Dialog({
  btnName,
  Title,
  children,
  handleCancel,
  visible,
  showModal,
  width,
}) {
  return (
    <>
      <Button
        style={{ borderRadius: "0.5em" }}
        type="primary"
        onClick={showModal}
      >
        {btnName}
      </Button>
      <Modal
        maskClosable={false}
        visible={visible}
        title={Title}
        showModal={showModal}
        onCancel={handleCancel}
        className="global-modal"
        width={"30vw"}
        style={{ background: "white", top: "0px" }}
      >
        {children}
      </Modal>
    </>
  );
}

export default Dialog;
