import React, { useState } from "react";
import PageSearch from "../../components/common/PageSearch";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Switch,
  message,
  Modal,
  Avatar,
  Tag,
  Tooltip,
  Checkbox,
} from "antd";
import { useQuery, useMutation } from "react-query";
import debounce from "lodash/debounce";
import { api } from "../../services/Api";
import { BiBellPlus } from "react-icons/bi";
import DebounceSelect from "../../components/common/DebounceSelect";
import moment from "moment";
import NotificationImage from "../../assets/images/notification.png";
import { restriction } from "../../constant/Restrictions";
import { fetchUser } from "../../services/User";

function Notification() {
  const [addNotificationVisible, setAddNotificationVisible] = useState(false);
  const [regexQuery, setRegexQuery] = useState("");
  const [isNotificationModalVisible, setIsNotificationModalVisible] =
    useState(false);
  const { isUserLoading, data: userData } = useQuery("user", fetchUser);
  const [params, setParams] = useState({
    sort: "-createdAt",
    page: 1,
    limit: 10,
    skip: 0,
  });

  const fetchNotifications = () => {
    return api.get("/notifications", {
      params: {
        ...params,
        title: encodeURI(`/${regexQuery}/i`),
      },
    });
  };

  const [form] = Form.useForm();
  const global = Form.useWatch("global", form);
  const users = Form.useWatch("users", form);
  const employees = Form.useWatch("employees", form);

  const {
    isLoading: notification_loading,
    data: notification_data,
    refetch: notification_refetch,
  } = useQuery(["notifications", regexQuery, params], fetchNotifications);
  console.log("notification data", notification_data);
  const postNotification = (payload) => {
    return api.post("/notifications", payload);
  };

  const [notifictation_clicked, setNotification_clicked] = useState({});

  const {
    mutate: notification_add_mutate,
    isLoading: notification_add_loading,
  } = useMutation(postNotification, {
    onSuccess: () => {
      notification_refetch();
      form.resetFields();
      setAddNotificationVisible(false);
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });

  const handleCancel = () => {
    setIsNotificationModalVisible(false);
  };

  const getUsers = async (queryString) => {
    return api
      .get("/users", {
        params: {
          firstName: encodeURI(`/${queryString}/i`),
        },
      })
      .then((body) =>
        body?.data?.docs?.map((user) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user._id,
        }))
      );
  };
  ///////////////mark notification as read/////////////////

  const MarkRead = (id) => {
    console.log("id", `/notifications/${id}/mark-as-read`);
    return api.patch(`/notifications/${id}/mark-as-read`);
  };
  const { mutate: markAsRead } = useMutation(MarkRead);

  const getCompanies = async (queryString) => {
    return api
      .get("/companies", {
        params: {
          name: encodeURI(`/${queryString}/i`),
        },
      })
      .then((body) =>
        body?.data?.docs?.map((company) => ({
          label: `${company.name}`,
          value: company._id,
        }))
      );
  };

  return (
    <div className="main-container-N">
      <Row className="content-apart mb-10">
        <h1 className="main-title">Notification</h1>
        <Row>
          <PageSearch
            onChange={(e) => {
              debounce(setRegexQuery(e.target.value), 800);
            }}
            placeholder="Search for notifications"
          />
          {!restriction[userData?.data.role.slug].includes(
            "change_notification"
          ) && (
            <Button
              style={{ borderRadius: "0.5em" }}
              type="primary"
              onClick={() => {
                setIsNotificationModalVisible(true);
              }}
            >
              <BiBellPlus size={20} />
            </Button>
          )}

          <Modal
            visible={isNotificationModalVisible}
            okText="Send"
            onCancel={handleCancel}
            width={"30vw"}
            style={{ background: "white", top: "0px" }}
            className="global-modal"
            footer={[
              <Button
                loading={notification_add_loading}
                type="primary"
                htmlType="submit"
                onClick={() => {
                  form
                    .validateFields()
                    .then((values) => {
                      notification_add_mutate(values);
                      message.success(
                        <div>
                          <p style={{ color: "black" }}>Notification sent!</p>
                        </div>
                      );
                    })
                    .catch((info) => {
                      console.log("Validate Failed:", info);
                    });
                }}
              >
                Send Notification
              </Button>,
            ]}
          >
            <div className="form-wrapper">
              <Form
                layout="vertical"
                form={form}
                style={{ marginTop: "2rem" }}
                name="basic"
                autoComplete="off"
              >
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Please input title!",
                    },
                  ]}
                >
                  <Input placeholder="title" />
                </Form.Item>

                <Form.Item
                  label="Desc"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please input description!",
                    },
                  ]}
                >
                  <Input placeholder="description" />
                </Form.Item>

                {!users && !employees && (
                  <Form.Item label="Global" name="global">
                    <Switch />
                  </Form.Item>
                )}

                {!global && (
                  <>
                    {!employees && (
                      <Form.Item label="Users" name="users">
                        <Switch />
                      </Form.Item>
                    )}
                    {users ? (
                      <>
                        <div
                          style={{
                            marginBottom: "20px",
                          }}
                        >
                          <Checkbox>D1</Checkbox>
                          <Checkbox>D2</Checkbox>
                          <Checkbox>D3</Checkbox>
                          <Checkbox>D4</Checkbox>
                        </div>
                      </>
                    ) : (
                      <Form.Item label="Employees" name="employees">
                        <Switch />
                      </Form.Item>
                    )}
                  </>
                )}
              </Form>
            </div>
          </Modal>
        </Row>
      </Row>

      <Row
        style={{
          padding: "1em",
        }}
      >
        <Col span={24}>
          {notification_data?.data?.docs
            ?.filter(
              (e) =>
                e.global ||
                userData.data.role.slug === "superadmin" ||
                userData.data.role.slug === "moderator" ||
                userData.data.role.slug === "admin" ||
                e?.users?.map((u) => u?._id).includes(userData.data.id)
            )
            ?.map((n, i) => {
              console.log("n", n);
              return (
                <>
                  <div
                    style={{
                      display: "flex",
                      padding: "1em",
                      marginBottom: "2em",
                      background: notifictation_clicked[i] ? "#fff" : "#f2f5f6",
                      borderRadius: "1em",
                      minHeight: "10vh",
                      justifyContent: "space-between",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      markAsRead(n?._id);
                    }}
                    key={i}
                    className="fit-res"
                  >
                    <div onClick={() => console.log("clicked")}>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <Avatar
                          className="notify-img"
                          src={NotificationImage}
                        />
                        &nbsp;&nbsp;
                        <h1
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {n.title}
                        </h1>
                        <p
                          style={{
                            opacity: 0.8,
                            fontSize: "8px",
                            marginLeft: "22px",
                            marginTop: "7px",
                          }}
                          className="date-notify"
                        >
                          {moment(n.createdAt).format("MMM Do, h:mm a")}
                        </p>
                      </div>

                      <p
                        style={{
                          fontSize: "14px",
                          opacity: 0.9,
                          marginLeft: "38px",
                        }}
                      >
                        {n.description}
                      </p>
                    </div>
                    <div
                      className="sep-details"
                      style={{
                        display: "flex",
                      }}
                    >
                      <div>
                        {n?.companies?.map((c) => {
                          return (
                            <>
                              <Tooltip
                                title={
                                  <div>
                                    <p>{c.name}</p>
                                  </div>
                                }
                              >
                                <Tag
                                  color="#f50"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  Company
                                </Tag>
                              </Tooltip>
                            </>
                          );
                        })}
                      </div>
                      <div>
                        {n?.users?.map((u) => {
                          return (
                            <>
                              <Tooltip
                                title={
                                  <div>
                                    <p>{u.userName}</p>
                                  </div>
                                }
                              >
                                <Tag
                                  color="#87d068"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  User
                                </Tag>
                              </Tooltip>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </Col>
      </Row>
    </div>
  );
}

export default Notification;
