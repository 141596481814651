import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
    },
  },
};

const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function CLineChart({ data }) {
  const data1 = {
    labels,
    datasets: [
      {
        label: "Pending Task",
        data: labels.map(
          (_, index) =>
            data?.find(
              (value) =>
                value._id?.status === "Pending" &&
                value._id?.month === index + 1
            )?.total ?? 0
        ),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Ongoing Task",
        data: labels.map(
          (_, index) =>
            data?.find(
              (value) =>
                value._id?.status === "Ongoing" &&
                value._id?.month === index + 1
            )?.total ?? 0
        ),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Completed Task",
        data: labels.map(
          (_, index) =>
            data?.find(
              (value) =>
                value._id?.status === "Completed" &&
                value._id?.month === index + 1
            )?.total ?? 0
        ),
        borderColor: "rgb(71, 235, 53)",
        backgroundColor: "rgba(123, 235, 53, 0.5)",
      },
    ],
  };
  return (
    <div className="clinechart">
      <Line height={10.5} width={30} options={options} data={data1} />
    </div>
  );
}

export default CLineChart;
