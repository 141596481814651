import React from "react";
import { Avatar, Image } from "antd";

function ProfileCard({ title, children, imgSrc, icon }) {
  return (
    <div className="profile-card shadow-card">
      {/* <Avatar className="card-img" size="large" src={<Image src={imgSrc} />} /> */}
      <Avatar className="card-img" size="large" src={imgSrc} />
      <div
        style={{
          marginTop: "-22px",
          marginLeft: "65%",
          fontSize: "16px",
          cursor: "pointer",
          backgroundColor: "#D3D3D3",
          width: "20px",
          height: "20px",
          borderRadius: "60%",
        }}
        className="profile-edit"
      >
        {icon}
      </div>
      <h6 className="card-title">{title}</h6>
      {children}
    </div>
  );
}

export default ProfileCard;
