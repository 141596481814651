import React, { useState } from "react";
import { api } from "../../../services/Api";
import { useQuery, useMutation } from "react-query";
import feed from "../../../assets/images/feedback.png";
import moment from "moment";
import FeedForm from "./FeedForm";
import { IoEllipsisVertical } from "react-icons/io5";
import { FaEdit, FaTrash } from "react-icons/fa";
import { MdModeEditOutline } from "react-icons/md";
import { Row, Col, Form, Avatar, Image, Dropdown, Space, Menu } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { restriction } from "../../../constant/Restrictions";
import { fetchUser } from "../../../services/User";
import Swal from "sweetalert2";

const FeedDetail = () => {
  let { feedId } = useParams();
  console.log(feedId);
  const navigate = useNavigate();
  const [feedForm] = Form.useForm();
  const { isUserLoading, data: userData } = useQuery("user", fetchUser);
  const [isFeedEditModalVisible, setIsFeedEditModalVisible] = useState(false);

  const getFeed = () => {
    return api.get(`/feeds/${feedId}`);
  };

  const {
    isLoading: feed_loading,
    data: feed_data,
    refetch: feed_refetch,
  } = useQuery("feed", getFeed);

  const updateFeed = (data) => {
    return api.patch(`/feeds/${feedId}`, data);
  };

  const { mutate: update_feed_mutate, isLoading: update_feed_loading } =
    useMutation(updateFeed, {
      onSuccess: () => {
        feed_refetch();
        feedForm.resetFields();
        setIsFeedEditModalVisible(false);
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });

  const handleFeedEditCancel = () => {
    feedForm.resetFields();
    setIsFeedEditModalVisible(false);
  };

  const handleFeedEditOk = () => {
    feedForm
      .validateFields()
      .then((values) => {
        console.log('====================================');
        console.log("values", values);
        console.log('====================================');
        // update_feed_mutate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const removeFeed = () => {
    return api.delete(`/feeds/${feedId}`);
  };

  const { mutate: delete_feed_mutate } = useMutation(removeFeed, {
    onSuccess: () => {
      navigate("/dashboard/feeds");
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });

  const handleFeedDelete = () => {
    Swal.fire({
      title: "Are you sure ?",
      text: "You want to delete this Feed.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Ok`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        delete_feed_mutate();
      }
    });
  };

  const updateFeedPic = (data) => {
    const payload = {
      featureImage: data.featureImage,
    };
    const formData = new FormData();
    formData.append("featureImage", data.featureImage);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    return api.patch(
      `/feeds/${feedId}/update-feature-img`,
      formData,
      config,
      payload
    );
  };

  const { mutate: update_feed_img_mutate, isLoading: update_feed_img_loading } =
    useMutation(updateFeedPic, {
      onSuccess: () => {
        feed_refetch();
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });

  const uploadImg = (featureImage) => {
    update_feed_img_mutate({ featureImage: featureImage });
  };

  return (
    <>
      <div className="main-container-N">
        <div className="breadcrumb">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <span
                className="main-title link-element"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Feed/
              </span>
              <span className="main-subtitle">Feed Detail</span>
            </div>

            <div style={{border:"2px solid red"}}>
              {!restriction[userData?.data.role.slug].includes(
                "change_feed"
              ) && (
                <Dropdown
                  overlay={
                    <Menu mode="horizontal">
                      <Menu.Item
                        key="one"
                        onClick={() => {
                          setIsFeedEditModalVisible(true);
                        }}
                        icon={
                          <FaEdit
                            style={{
                              color: "blue",
                              fontSize: "20px",
                              marginRight: "10px",
                            }}
                          />
                        }
                      >
                        Edit
                      </Menu.Item>

                      <Menu.Item
                        key="two"
                        onClick={() => {
                          handleFeedDelete();
                        }}
                        icon={
                          <FaTrash
                            style={{
                              color: "red",
                              fontSize: "20px",
                              marginRight: "10px",
                            }}
                          />
                        }
                      >
                        Delete
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Space>
                    <IoEllipsisVertical
                      style={{
                        cursor: "pointer",
                        color: "#00ABC5",
                      }}
                      size={20}
                    />
                  </Space>
                </Dropdown>
              )}
            </div>
          </div>
        </div>

        <Row>
          <Col span={24}>
            <>
              <div className="single-feed-detail">
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <Avatar
                    className="feed-detail-image"
                    src={
                      <Image src={feed_data?.data?.featureImage?.url ?? feed} />
                    }
                    size="large"
                  />
                  {!restriction[userData?.data.role.slug].includes(
                    "change_feed"
                  ) && (
                    <label
                      style={{
                        cursor: "pointer",
                      }}
                      htmlFor="file-input-feed"
                    >
                      <MdModeEditOutline
                        style={{
                          marginTop: "110px",
                          marginLeft: "8px",
                          cursor: "pointer",
                          color: "#00ABC5",
                          marginBottom: "10px",
                        }}
                        size={20}
                      />
                    </label>
                  )}

                  <input
                    id="file-input-feed"
                    type="file"
                    className="hidden"
                    accept="image/png, image/jpeg"
                    onChange={(e) => {
                      uploadImg(e.target.files[0]);
                    }}
                  />
                </div>
                <div className="feed-text-detail">
                  <h1
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    {feed_data?.data?.title}
                  </h1>

                  <p>{feed_data?.data?.description}</p>
                  <p
                    style={{
                      opacity: 0.7,
                      textAlign: "end",
                    }}
                  >
                    {moment(feed_data?.data?.createdAt).format(
                      "YYYY/MM/DD , h:mm a"
                    )}
                  </p>
                </div>
              </div>
            </>
          </Col>
        </Row>
      </div>
      {isFeedEditModalVisible && (
        <FeedForm
          isModalVisible={isFeedEditModalVisible}
          form={feedForm}
          okText="Update"
          handleCancel={handleFeedEditCancel}
          handleOk={handleFeedEditOk}
          // handleOk={(e)=>console.log(e)}
          loading={update_feed_loading}
          headingText="Update this feed"
          initialValues={feed_data?.data}
        />
      )}
    </>
  );
};

export default FeedDetail;
